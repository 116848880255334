import React, { Component } from "react";
import Footer from "../../Comp/Footer";
import Header from "../../Comp/Header";
import Notiflix from "notiflix";
import PostApiCall from "../../API/POSTAPI";
import { Helmet } from "react-helmet";
import event from "../../assets/banner/SpeakersImage/event-image.jpg";

export default class EventsComing extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Events | Hide India | Hospitality Interior & Design Expo 2024
          </title>
          <meta
            name="description"
            content="Attend India’s exclusive trade fair on 27th-29th September 2024 in Goa. Explore furniture, lighting, and design trends for the hospitality industry. Register now"
          />
          <meta
            property="og:title"
            content="Events | Hide India | Hospitality Interior & Design Expo 2024"
          />
          <meta
            property="og:description"
            content="Attend India’s exclusive trade fair on 27th-29th September 2024 in Goa. Explore furniture, lighting, and design trends for the hospitality industry. Register now"
          />

          <meta
            name="keyword"
            content="Hospitality Interior & Design Expo 2023,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions"
          />

          <meta property="og:url" content="https://hideindia.com/events" />
          <link rel="canonical" href="https://hideindia.com/events" />
          <meta
            property="og:image"
            content="https://hideindia.com/static/media/banner.8f03bebabd819f34df2b.jpg"
          />
        </Helmet>
        <Header />

        <section class="slider ">
          <div class="container-fluid p-0">
            <img
              src="/assets/images/inner-banner/seminarragistrationweb.webp"
              alt=""
              class="bannerimg2 forwebsite"
            />
            <img
              src="/assets/images/banner/mobilebanner/seminarragistrationphn.webp"
              alt=""
              class="bannerimg2 formobile"
            />
            <div class="content">
              <div class="bannertext"></div>
            </div>
          </div>
        </section>

        <div className="container-fluid my-5 overflow-hidden">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-12 text-lg-center">
              <div class="title event">
                <h1 style={{ marginBottom: "10px" }}>Events</h1>

                <div className="mt-3">
                  <p className="text-center">
                    JD School of Design is thrilled to host the official Model
                    Hunt for MR & MS HIDE 2024 as an education partner for the
                    HIDE India exhibition!
                  </p>
                  <p className="text-center">
                    We invite aspiring models to participate in this exciting
                    competition.
                  </p>
                </div>
              </div>
            </div>

            {/*  */}

            {/* <div className="stall_button stall_button-top-nav seminar-button mt-3">
              <a href="https://forms.gle/hBUbu2kHgyikYPXs9" target="_blank">
                Model Hunt
              </a>
            </div> */}
          </div>
          <div className="row mt-3">
            <div className="col-lg-7">
              <p>
                <b>
                  To register, please complete the form below with your accurate
                  details.
                </b>
              </p>
              <p>
                Join us on this fantastic opportunity to shine on the runway and
                kickstart your modelling career!
              </p>
              <p>
                To enter, submit a 2-minute audition video showcasing your
                introduction and runway walk.
              </p>
              <p className="subheading">
                <b>HOW TO SUBMIT:</b>
              </p>
              <p>
                DM your video to{" "}
                <a
                  href="https://www.instagram.com/jdinstitute_goa/?img_index=1"
                  target="_blank"
                  className="text-dark">
                  <b>@jdinstitute_goa</b>
                </a>{" "}
                and email the same to
                <a
                  href="mailto:divya.a@jdinstitute.edu.in"
                  target="_blank"
                  className="text-dark">
                  {" "}
                  <b> divya.a@jdinstitute.edu.in</b>
                </a>
              </p>
              <div className=" stall_button-top-nav seminar-button mt-2 mb-2">
                <a href="https://forms.gle/hBUbu2kHgyikYPXs9" target="_blank">
                  Register for Model Hunt
                </a>
              </div>
              <p>
                <b>Deadline:</b> 9th September by 1 PM
              </p>
              <p className="subheading">
                <b>WHAT'S NEXT?</b>
              </p>
              <p>
                Shortlisted candidates will be invited for a Grooming session
                followed by Trials and Fittings.
              </p>
              <ul className="capital-letter p-2">
                <li>
                  <b>ATTRACTIVE PRIZES AWAIT!</b>
                </li>
                <li>
                  <b>CASH PRIZES</b>
                </li>
                <li>
                  <b>CERTIFICATES AND A CHANCE TO WEAR DESIGNER GARMENTS</b>
                </li>
                <li>
                  <b>OPPORTUNITY TO MODEL FOR FUTURE EVENTS AT JD INSTITUTE</b>
                </li>
              </ul>

              <p className="subheading">
                <b>Eligibility Requirements:</b>
              </p>
              <ul className="events-dot">
                <li>1. Must be a college student</li>
                <li>
                  2. Should be following our official page{" "}
                  <a
                    href="mailto:divya.a@jdinstitute.edu.in"
                    target="_blank"
                    className="text-dark">
                    <b>@jdinstitute_goa</b>
                  </a>
                </li>
              </ul>

              <p>
                For more details, DM us at{" "}
                <a
                  href="mailto:divya.a@jdinstitute.edu.in"
                  target="_blank"
                  className="text-dark">
                  <b>@jdinstitute_goa</b>
                </a>{" "}
                or contact us at{" "}
                <a href="tel:+919021900400.">
                  <b className="text-dark">+91-90219 00400.</b>
                </a>
              </p>
              <div className="stall_button stall_button-top-nav seminar-button mt-2 mb-2">
                <a href="https://forms.gle/hBUbu2kHgyikYPXs9" target="_blank">
                  Register for Model Hunt
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="event-image mt-lg-0 mt-3">
                {/* <img src="/assets/images/overviewming.webp" className='img-responsive' width="100%" /> */}
                <img
                  src={event}
                  alt="About us"
                  class="img-fluid aboutusimg pe-xl-2"
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12">
              <div className="stall_button stall_button-top-nav seminar-button">
                <a href="https://forms.gle/hBUbu2kHgyikYPXs9" target="_blank">
                  Model Hunt
                </a>
              </div>
            </div>
          </div> */}
        </div>

        <Footer />
      </>
    );
  }
}
