import React, { useEffect } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import logo from "../assets/banner/hidelogo.png";
import Image1 from "../assets/banner/image1.png.webp";
import Image2 from "../assets/banner/image2.png.webp";
import Image3 from "../assets/banner/image3.png.webp";
import Image4 from "../assets/banner/image4.png.webp";
import Image5 from "../assets/banner/image5.png.webp";
import BottomNavbar from "./BottomNavbar";
import DirectoryList from "./DirectoryList";
import "./Showdirectory.css";
import { useLocation } from "react-router-dom";
const MainScreen = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  React.useEffect(() => {
    if (window.innerWidth > 500) {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <div className="d-flex w-100 justify-content-center bg-white">
        <img
          //   className="col-11"
          src={logo}
          width="87%"
          style={{ borderRadius: "7px", marginTop: "10px" }}
        />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={10}
              pagination={{ clickable: true }}
              loop={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              style={{ paddingLeft: "70px", marginTop: "10px" }}>
              <SwiperSlide>
                <div
                  class="mb-3"
                  style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                  <img
                    src={Image1}
                    width="100%"
                    style={{ borderRadius: "7px" }}
                  />
                  <h6 className="swiper-text">Furniture Zone</h6>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  class="mb-3"
                  style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                  <img
                    src={Image2}
                    width="100%"
                    style={{ borderRadius: "7px" }}
                  />
                  <h6 className="swiper-text">Furnishings Zone</h6>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  class="mb-3"
                  style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                  <img
                    src={Image3}
                    width="100%"
                    style={{ borderRadius: "7px" }}
                  />
                  <h6 className="swiper-text">Al Fresco Zone</h6>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  class="mb-3"
                  style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                  <img
                    src={Image4}
                    width="100%"
                    style={{ borderRadius: "7px" }}
                  />
                  <h6 className="swiper-text">Lighting Zone</h6>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  class="mb-3"
                  style={{ boxShadow: "0 2px 5px rgb(162 169 204 / 24%)" }}>
                  <img
                    src={Image5}
                    width="100%"
                    style={{ borderRadius: "7px" }}
                  />
                  <h6 className="swiper-text">Surfacing Zone</h6>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* <div className="title px-3">
        <h1 className=" mt-1">Categories</h1>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <div className="category-box">
                <div className="category-card">
                  <div className="image w-100">
                    <img src={Image1} className="img-fluid" alt="" />
                  </div>
                  <p>Category Name</p>
                </div>
                <div className="category-card">
                  <div className="image">
                    <img src={Image1} className="img-fluid" alt="" />
                  </div>
                  <p>Category Name</p>
                </div>
                <div className="category-card">
                  <div className="image">
                    <img src={Image1} className="img-fluid" alt="" />
                  </div>
                  <p>Category Name</p>
                </div>
                <div className="category-card">
                  <div className="image">
                    <img src={Image1} className="img-fluid" alt="" />
                  </div>
                  <p>Category Name</p>
                </div>
                <div className="category-card">
                  <div className="image">
                    <img src={Image1} className="img-fluid" alt="" />
                  </div>
                  <p>Category Name</p>
                </div>
                <div className="category-card">
                  <div className="image">
                    <img src={Image1} className="img-fluid" alt="" />
                  </div>
                  <p>Category Name</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <DirectoryList />

      {/* <MobileFooter /> */}
      <BottomNavbar />
    </>
  );
};

export default MainScreen;
