import { FaHome } from "react-icons/fa";
import { MdEventNote } from "react-icons/md";
import { Link } from "react-router-dom";
// import { LuLayoutDashboard } from "react-icons/lu";
import { BsBuildingsFill } from "react-icons/bs";
import { RiLayout6Fill } from "react-icons/ri";
import { HiTicket } from "react-icons/hi2";

import "./Showdirectory.css";
export default function BottomNavbar() {
  return (
    <section className="bottom-navbar p-2 fixed-bottom">
      <ul>
        <li>
          <Link to="/dashboard">
            <FaHome />
            Home
          </Link>
        </li>
        <li>
          <Link to="/directorylist">
            <BsBuildingsFill />
            List of Companies
          </Link>
        </li>
        <li>
          <Link to="/my-ticket">
            <HiTicket />
            My Ticket
          </Link>
        </li>
        <li>
          <Link to="/seminar-calendar">
            <MdEventNote />
            Seminar Calendar
          </Link>
        </li>
        <li>
          <a href={require("../pdf/Hide India Layout with name.pdf")} target="_blank">
            <RiLayout6Fill />
            View Layout Plan
          </a>
        </li>
      </ul>
    </section>
  );
}
