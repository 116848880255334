
import Notiflix from 'notiflix';
import React, { useState } from 'react';
import './mobile.css';

import PostApiCall from '../API/POSTAPI';

const SplashScreen = () => {

    React.useEffect(() => {

        if (window.innerWidth > 500) {
            window.location.href = "/"
        }

    }, [])


    const [name, setname] = useState("")
    const [company, setcompany] = useState("")
    const [mobile, setmobile] = useState("")
    const [email, setemail] = useState("")


    const SaveData = () => {

        Notiflix.Loading.dots()

        PostApiCall.postRequest(
            {
                id: null,
                name: name,
                company: company,
                mobile: mobile,
                email: email,
            },
            "Update_Directory_Visitors"
        ).then((resultscity) =>
            resultscity.json().then((objdata) => {
                if (resultscity.status == 200 || resultscity.status == 201) {
                    localStorage.setItem("CustomData", JSON.stringify(objdata.data))
                    window.location.href = "/dashboard"
                }
                Notiflix.Loading.remove()
            })
        );

    }

    return (




        <div className='container-fluid p-0 position-relative splash-bg' >

            <img src={require("../assets/image/hide-banner.jpeg")} className="splash-img" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" />

            <div className='splash-footer'>Powered By <a href="http://globaltrendz.com" target="_blank" style={{ color: "inherit" }}>Global Trendz</a></div>

            <div className='splash-canvas'>
                <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                    <div class="offcanvas-header movingline">
                        <h5 class="offcanvas-title" id="offcanvasBottomLabel">Hospitality Interior &<br /> Design Expo - 2024</h5>
                        <i class="bi bi-x-circle-fill close-splash-btn" data-bs-dismiss="offcanvas" aria-label="Close"></i>
                    </div>
                    <div class="offcanvas-body small">

                        <h6 className='mb-3 '>Tell us a little about you!</h6>
                        <input type="text" class="form-control mb-3" value={name} onChange={e => setname(e.target.value)} placeholder="Name*" />
                        <input type="" class="form-control mb-3" value={company} onChange={e => setcompany(e.target.value)} placeholder="Company Name" />
                        <input type="text" class="form-control mb-3" value={mobile} onChange={e => {
                            if (/^[0-9]*$/.test(e.target.value) && e.target.value.length < 11) {
                                setmobile(e.target.value)
                            }
                        }} placeholder="Mobile Number*" />
                        <input type="email" class="form-control mb-3" value={email} onChange={e => {
                            setemail(e.target.value)
                        }} placeholder="Email Address*" />

                        <button className='btn view-directory-btn mt-3 w-100' onClick={() => {

                            if (name != "") {
                                if (mobile != "") {
                                    if (mobile.length == 10) {

                                        if (email != "") {
                                            if (/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                                                SaveData()
                                            } else {
                                                Notiflix.Notify.failure("Please Enter Valid Email")
                                            }

                                        } else {
                                            Notiflix.Notify.failure("Please Enter Valid Email")
                                            // SaveData()
                                        }
                                    } else {
                                        Notiflix.Notify.failure("Enter Valid Mobile Number")
                                    }
                                } else {
                                    Notiflix.Notify.failure("Enter Your Mobile Number")
                                }
                            } else {
                                Notiflix.Notify.failure("Enter Your Name")
                            }
                        }}>View Preview Catalogue</button>

                    </div>
                </div>
            </div>

        </div>









    )
}

export default SplashScreen