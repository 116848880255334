import QRCode from 'qrcode';
import { useState } from 'react';
import logo from "../assets/banner/hidelogo.png";
import "./Showdirectory.css";
export default function CatalogQrCode() {
    const [qrUrl, setQrUrl] = useState("");
    QRCode.toDataURL(`https://hideindia.com/previewcatalogue`).then((url) => {
        setQrUrl(url);
    })
    return (
        <>
            <div className="d-flex w-100 justify-content-center bg-white">
                <img
                    //   className="col-11"
                    src={logo}
                    width="87%"
                    style={{ borderRadius: "7px", marginTop: "10px" }}
                />
            </div>
            <img src={qrUrl} className="img-fluid" />
        </>
    );
}
