import React from "react";
import Image1 from "../assets/banner/bannernew.png";
import Image2 from "../assets/banner/Furniture-Zone2.png";
import Image3 from "../assets/banner/Furnishing-Zone1.png";
import Image4 from "../assets/banner/Lighting-Zone3.png";
import Image5 from "../assets/banner/Al-Fresco-Zone1.png";
import Image6 from "../assets/banner/Surfacing-Zone4.png";
// import Image7 from '../../publicassets/assets/images/footerlogo.png';
import "./category.css";

const Banner = () => {
  return (
    <>
      <div className="container-fluid banner-section p-0 m-0">
        <div className="row p-0 m-0">
          <div className="col-lg-12 p-0 ">
            <img src="/assets/images/h1.webp" className="mobileimage" />
            <img
              src="/assets/images/banner_new.png.webp"
              style={{ width: "100%" }}
              class="d-block w-100"
              alt="home"
              className="webbanner"
            />
            <div class="bannertext bannertext-lax">
              <h1 style={{ color: "#ffffff" }} className="homeline banner-h1-text">
                Hospitality Interior &amp; Design Expo 2024
              </h1>
              <h3  className="banner-bold-text" style={{ color: "#ffffff" }}>
                <b>
                  27<sup>th</sup>, 28<sup>th</sup>, 29<sup>th</sup> September 2024{" "}
                  <br />
                  {/* Dr. Shyama Prasad Mukherjee Indoor Stadium (A/C),
                  Panjim, Goa */}

Dr. Shyam Prasad Mukherjee Stadium, Panjim, Goa.
                </b>
              </h3>

              <p class="bannerp banner-p-text">
                India's only Exclusive Trade Fair on Furniture, Furnishings,
                Interiors,
                <span className="banner-half-text"> Lighting, Design &amp; Surfacing for Hospitality
                Industry.</span>
              </p>
            </div>
            <div className="lax phn-view-below-banner-bookbtn">
              <ul className='d-flex justify-content-center mt-4 pt-2'>
                <li className="book-stall-btn-banner-animation heart">
                  <span> </span>
                  <span> </span>
                  <span> </span>
                  <span> </span>
                  <a href='/exhibitor-registration' target="_blank" className='stall_button stall_button-banner-phnview banner-btn-phn-view-two bg-white px-sm-4 py-sm-3 px-2 py-2 fs-sm-4 fs-6' style={{ border: '1px solid #000' }}>Book Your Stall</a>
                </li>
                <li className="book-stall-btn-banner-animation heart">
                  <span> </span>
                  <span> </span>
                  <span> </span>
                  <span> </span>
                  <a href='/visitor-registration' target="_blank" className='stall_button banner-btn-phn-view-two stall_button-banner-phnview bg-white px-sm-4 py-sm-3 px-2 py-2 fs-sm-4 fs-6' style={{ border: '1px solid #000' }}>Visitor Registration</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
