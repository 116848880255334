
import React from 'react'

const ShowOverview = () => {
    return (
        <section class="overviewsection" id='overview-navbar'>
            <div class="container-fluid">
                <div class="row">
                    <div class="title" id="overview">
                        <h2>Show Overview</h2>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-lg-7 ">
                            <p class="overviewtext mt-3">
                                {/* ***Old data*** */}
                                {/* The Hospitality Interior & Design Expo 2023 is India's leading event for the
                                designs   behind   business   growth!   This   un-   missable   event   unites   the
                                industry’s   leading   creative     with   a   plethora   of   solutions   they   need   to
                                transform   their   business,   customer   loyalty   and   ultimately   increase   their
                                profits.   All   under   one   roof,   visitors   can   gain   invaluable   insights   from   the
                                sectors   leading   visionaries   as   they   reveal   their   secrets   to   success,   whilst
                                some   of   the   biggest   brands   will   be   on   hand   showcasing   the   very   best
                                products the industry has to offer! */}

                                {/* ***New data*** */}
                                The Hospitality Interior & Design Expo 2024 is India's leading event for the designs behind business growth! This unmissable event unites the industry’s leading creatives with a plethora of solutions they need to transform their business,customer loyalty and ultimately increase their profits. All under one roof, visitors can gain invaluable insights from the sector's leading visionaries as they reveal their secrets to success, whilst some of the biggest brands will be on hand showcasing the very best products the industry has to offer!
                            </p>

                            <h3 class="subheading">Who Should Attend ?</h3>

                            <div class="overviewlist">

                                <ul style={{ fontSize: "14px" }} >
                                    {/* ***old data *** */}
                                    {/* <li>Hospitality Business Owners Interior Designers</li> */}

                                    {/* ***** New data ****** */}
                                    <li>Hospitality Business Owners </li>
                                    <li>Interior Designers</li>
                                    <li>Heads of Design</li>
                                    <li>Creative Directors</li>
                                    <li>Brand Managers</li>
                                    <li>Heads of Marketing</li>
                                </ul>

                                <ul class="ullist">
                                    <li>Graphic Designers</li>
                                    <li>Directors</li>
                                    <li>CEO’s</li>
                                    <li>Business Development Directors</li>
                                    <li>Architects</li>
                                    {/* <li>Professionals working across all forms of the design sector</li> */}
                                </ul>

                            </div>
                        </div>

                        <div class=" col-lg-5 d-flex align-items-center mt-4 mt-lg-0">
                            <img src="/assets/images/overviewming.webp" className='img-responsive' width="100%" />
                        </div>
                        {/* <div id="abouttheshow"></div> */}

                    </div>
                </div>
            </div>

        </section>
    )
}

export default ShowOverview