import React, { Component } from "react";
import Footer from "../../Comp/Footer";
import Header from "../../Comp/Header";
import Notiflix from "notiflix";
import PostApiCall from "../../API/POSTAPI";
import { Helmet } from "react-helmet";
import ajoshi from "../../assets/banner/SpeakersImage/ajoshi.jpg";
import Chaitanya_Gokhale_Photo from "../../assets/banner/SpeakersImage/Chaitanya_Gokhale_Photo.jpeg";
import Sanjiv_Khandelwal from "../../assets/banner/SpeakersImage/Sanjiv_Khandelwal.JPG";
import Rohitha from "../../assets/banner/SpeakersImage/Rohitha.png";
import Bruce from "../../assets/banner/SpeakersImage/Bruce.jpeg";
import Neysa from "../../assets/banner/SpeakersImage/Neysa.jpg";
import Nischay_Gowda from "../../assets/banner/SpeakersImage/Nischay_Gowda.jpg";
import Sandra_Sequera from "../../assets/banner/SpeakersImage/Sandra_Sequera.png";
import Shweta_Mariyappanavar from "../../assets/banner/SpeakersImage/Shweta_Mariyappanavar.jpg";
import Tallulah from "../../assets/banner/SpeakersImage/Tallulah.jpeg";
import Vincent from "../../assets/banner/SpeakersImage/Vincent.png";
import Amit from "../../assets/banner/SpeakersImage/Ar. Amit Parashar.jpeg";
import Aniruddha from "../../assets/banner/SpeakersImage/Aniruddha.jpeg";

export default class SeminarRegistration extends Component {
  constructor() {
    super();
    this.state = {
      qrdata: "",

      CountryData: [],

      EmailRegex:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

      NumRegex: /^[0-9]*$/,

      name: "",
      mobile: "",
      emailid: "",
      designation: "",
      company: "",
      seminarregistered1: "",
      seminarregistered2: "",
      seminarregistered3: "",
      seminarregistered4: "",
      seminarregistered5: "",
    };
  }
  addVisitor = async () => {
    PostApiCall.postRequest(
      {
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.emailid,
        designation: this.state.designation,
        company: this.state.company,
        seminarregistered:
          this.state.seminarregistered1 +
          "," +
          this.state.seminarregistered2 +
          "," +
          this.state.seminarregistered3 +
          "," +
          this.state.seminarregistered4 +
          "," +
          this.state.seminarregistered5,
      },
      "Update_Seminar_Registrations"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          PostApiCall.postRequest(
            {
              id: obj.data[0].newid,
              name: this.state.name,
              mobile: this.state.mobile,
              email: this.state.emailid,
              designation: this.state.designation,
              company: this.state.company,
              seminarregistered:
                this.state.seminarregistered1 +
                "," +
                this.state.seminarregistered2 +
                "," +
                this.state.seminarregistered3 +
                "," +
                this.state.seminarregistered4 +
                "," +
                this.state.seminarregistered5,
            },
            "Send_Seminar_Registration_Mailer"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                Notiflix.Loading.remove();
                Notiflix.Notify.success(
                  "Thank you for registering with us, please check your mail."
                );
                window.location.reload();
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(obj.data);
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };
  render() {
    return (
      <>
        <Helmet>
          <title>
            Seminar Registration | Hide India | Hospitality Interior & Design
            Expo 2024
          </title>
          <meta
            name="description"
            content="Attend the leading trade fair on 27th-29th September 2024 at Dr. Shyam Prasad Mukherjee Stadium, Goa. Discover cutting-edge furniture, lighting, and interior designs"
          />
          <meta
            property="og:title"
            content="Seminar Registration | Hide India | Hospitality Interior & Design Expo 2024"
          />
          <meta
            property="og:description"
            content="Attend the leading trade fair on 27th-29th September 2024 at Dr. Shyam Prasad Mukherjee Stadium, Goa. Discover cutting-edge furniture, lighting, and interior designs"
          />

          <meta
            name="keyword"
            content="Hospitality Interior & Design Expo 2023,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions"
          />

          <meta
            property="og:url"
            content="https://hideindia.com/seminar-registration"
          />
          <link
            rel="canonical"
            href="https://hideindia.com/seminar-registration"
          />
          <meta
            property="og:image"
            content="https://hideindia.com/static/media/banner.8f03bebabd819f34df2b.jpg"
          />
        </Helmet>
        <Header />

        <section class="slider ">
          <div class="container-fluid p-0">
            <img
              src="/assets/images/inner-banner/seminarragistrationweb.webp"
              alt=""
              class="bannerimg2 forwebsite"
            />
            <img
              src="/assets/images/banner/mobilebanner/seminarragistrationphn.webp"
              alt=""
              class="bannerimg2 formobile"
            />
            <div class="content">
              <div class="bannertext"></div>
            </div>
          </div>
        </section>

        <div className="container-fluid mt-4 comonseminarpadding"></div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="step1"
            role="tabpanel"
            aria-labelledby="step1-tab">
            <div className="row m-0 justify-content-center m-2 border p-3 rounded-3">
              <div
                className="row m-0 py-2 rounded-2 text-white mainparentseminar form align-items-center"
                style={{ backgroundColor: "#f7904c" }}>
                <h4 className="col-auto seminarvisitorheading">
                  Seminar Registration
                </h4>
                <div className="stall_button stall_button-top-nav seminar-button">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSewDmVxNJ5GAk9CUxz9DHe7o90Jpqkkn2J_ACkhQIU1PnM65w/viewform?fbclid=PAZXh0bgNhZW0CMTEAAaapHnh9qmzzMNqTCdqRF06D8uWdBeE2ZForAqlzqP3WlPTqIjeVyJRfYUo_aem_FS-PEHECciDk3CUBCDZMlw"
                    target="_blank">
                    Book Your Slot for Seminar
                  </a>
                </div>
              </div>

              <div className="row d-lg-none p-0 seminar-page">
                <div className="col-12 title text-centertitle p-0">
                  <p className="text-center fs-4 fw-bold mt-3">
                    27th to 29th September 2024
                  </p>
                  <h4>Day 1: 27/9/2024</h4>
                  <div className="seminar-card">
                    <div className="seminar-box flex-column">
                      <span>Time:</span>
                      <p>11.00 AM – 1.00 PM</p>
                    </div>
                    <div className="seminar-box">
                      <span>Session Name:</span>
                      <p>Inaugural Session</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Proceeding:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Welcome Address by ( JD Student)
                        </p>
                        <p className="border-bottom pb-2">
                          Mr. Jivesh Sachdev, <br /> Ms. Sandra Agnes D'Souza &
                          Delegates
                        </p>
                        <p className="border-bottom pb-2">Lighting of a lamp</p>
                        <p className="border-bottom pb-2">Felicitation</p>
                        <p>Photo Session</p>
                      </div>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Chair:</span>
                      <p>Ms. Sandra Agnes D'Souza</p>
                    </div>
                  </div>
                  <div className="seminar-card">
                    <div className="seminar-box flex-column">
                      <span>Time:</span>
                      <p>4.00 PM – 5.00 PM</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Name:</span>
                      <p>Speaker Session (Talk & QnA)</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Proceeding:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Welcome & Introductory Address by Ar. Gutham Pawar
                        </p>
                        <p className="border-bottom pb-2">
                          Ar. Amit Parashar, M. Arch,. University of British
                          Columbia, Product Design from Universita Delgi Studi
                          di Firenze, Florence, Italy. Principal Architect,
                          Design Workshop Goa.
                          <br />
                          Subject: Environment & Design
                        </p>
                        <p className="border-bottom pb-2 pt-1">Felicitation</p>
                        <p>Ar. Amit Parashar</p>
                      </div>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Chair:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Ms. Sandra Agnes D'Souza
                        </p>
                        <p>
                          Ms. Sandra Agnes D'Souza <br /> and Mr. Jivesh Sachdev
                        </p>
                      </div>
                    </div>
                  </div>
                  <h4>Day 2: 28/9/2024</h4>
                  <div className="seminar-card">
                    <div className="seminar-box flex-column">
                      <span>Time:</span>
                      <p>2:30 PM - 3: 00 PM</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Name:</span>
                      <p>Speaker Session (Talk & QnA)</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Proceeding:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Welcome & Introductory Address by Ar. Gutham Pawar
                        </p>
                        <p className="border-bottom pb-2">
                          Dr. Aniruddha Pawar, Ph.D., IIT Roorkee.
                          <br /> Dean, Faculty of Architecture, Planning &
                          Design, Goa University.
                          <br /> Subject: Green Buildings, Climate Responsive
                          Design
                        </p>
                        <p className="border-bottom pb-2 pt-1">Felicitation</p>
                        <p>Ar. Amit Parashar</p>
                      </div>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Chair:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Ms. Sandra Agnes D'Souza
                        </p>
                        <p>
                          Ms. Sandra Agnes D'Souza <br /> and Mr. Jivesh Sachdev
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="seminar-card">
                    <div className="seminar-box flex-column">
                      <span>Time:</span>
                      <p>3.30pm to 4.40pm</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Name:</span>
                      <p>Speaker Session (Talk & QnA)</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Proceeding:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Welcome & Introductory Address
                        </p>
                        <div className="d-flex">
                          <span>Monika Patel</span>
                          <p>Architects India</p>
                        </div>
                      </div>
                    </div>
                    <div className="seminar-box">
                      <span>Session Chair:</span>
                      <p>Mr. Jivesh Sachdev</p>
                    </div>
                  </div>
                  <div className="seminar-card">
                    <div className="seminar-box flex-column">
                      <span>Time:</span>
                      <p>4:30 PM – 6: 00 PM</p>
                    </div>
                    <div className="seminar-box">
                      <span>Session Name:</span>
                      <p>Workshops</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Proceeding:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Brief about JDSD followed by workshops
                        </p>
                        <p>(Workshops yet to be curated)</p>
                      </div>
                    </div>
                    <div className="seminar-box">
                      <p>Plan B</p>
                    </div>
                  </div>
                  <div className="seminar-card">
                    <div className="seminar-box flex-column">
                      <span>Time:</span>
                      <p>6:30 PM – 7: 00 PM</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Name:</span>
                      <p>Prize distribution – Mr & Ms Hide Competition</p>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Proceeding:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Hosted by JDSD Student
                        </p>
                        <p>Winners & Participants</p>
                      </div>
                    </div>
                    <div className="seminar-box flex-column">
                      <span>Session Chair:</span>
                      <div>
                        <p className="border-bottom pb-2">
                          Ms. Sandra Agnes D'Souza
                        </p>
                        <p>and Mr. Jivesh Sachdev</p>
                      </div>
                    </div>
                  </div>
                  <div className="seminar-card">
                    <div className="seminar-box flex-column">
                      <span>Time:</span>
                      <p className="border-bottom pb-2">11.00 AM – 1.00 PM</p>
                      <p>2:30 PM - 3: 00 PM</p>
                    </div>

                    <div className="seminar-box flex-column mb-2">
                      <span>Proceeding:</span>
                      <div>
                        <p>Speaker - Eco Furniture Recycle Bell</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* web view table */}

              <section className="webviewseminartable mt-4 mb-5 mx-0 px-0">
                <div
                  style={{
                    overflowX: "auto",
                    tableLayout: "auto",
                    width: "100%",
                  }}>
                  <table
                    className="tg tablewidthseminar"
                    style={{
                      overflowX: "auto",
                      tableLayout: "auto",
                    }}>
                    {/* <colgroup>
                        <col style={{ width: 110 }} />
                        <col style={{ width: 62 }} />
                        <col style={{ width: 188 }} />
                        <col style={{ width: 208 }} />
                        <col style={{ width: 420 }} />
                        <col style={{ width: 222 }} />
                      </colgroup> */}
                    <thead>
                      <tr className="thfontseminar">
                        <th className="tg-ioob text-white fs-4" colSpan={6}>
                          HOSPITALITY INTERIOR & DESIGN EXPO 2024
                        </th>
                        <th className="tg-ioob"></th>
                      </tr>
                      <tr>
                        <th colSpan={6} className="text-center fs-5 fw-bold">
                          27th to 29th September 2024
                        </th>
                        <th></th>
                      </tr>
                      <tr>
                        <th colSpan={6} className="text-center fw-bold">
                          AGENDA
                        </th>
                        <th></th>
                      </tr>
                      <tr className="thfontseminar">
                        <th className="tg-ioob text-white">Date</th>
                        <th className="tg-ioob text-white">
                          Indian Standard Time
                        </th>
                        <th className="tg-ioob text-white">Session Name</th>
                        <th className="tg-ioob text-white" colSpan={2}>
                          Proceeding
                        </th>
                        <th className="tg-ioob text-white">Session Chair</th>
                        <th className="tg-ioob text-white"></th>
                      </tr>
                      <tr>
                        <th className="text-center fs-5 fw-bold" colSpan={6}>
                          Day 1
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="seminartableborder">
                      <tr>
                        <td className="tg-9wq8" rowSpan={4}>
                          27th Sept. 2024
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          11.00 AM – 1.00 PM
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          Inaugural Session
                        </td>
                        <td className="tg-9wq8 p-0" rowSpan={4} colSpan={2}>
                          <tr>
                            <td className="border-0">
                              Welcome Address by ( JD Student)
                            </td>
                            <td className="tg-9wq8 border-top-0 border-bottom-0 border-end-0 p-0">
                              <tr className="border-0">
                                <td className="border-top-0 border-start-0 border-end-0">
                                  Mr. Jivesh Sachdev, Ms. Sandra Agnes D'Souza &
                                  Delegates
                                </td>
                              </tr>
                              <tr>
                                <td className="border-0 border-top-0">
                                  Lighting of a lamp
                                </td>
                              </tr>
                              <tr>
                                <td className="border-bottom-0 border-start-0 border-end-0">
                                  Felicitation
                                </td>
                              </tr>
                              <tr>
                                <td className="border-bottom-0 border-start-0 border-end-0">
                                  Photo Session
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          Ms. Sandra Agnes D'Souza
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                    {/* second  */}
                    <tr>
                      <th className="text-center fs-5 fw-bold" colSpan={6}>
                        Break for Refreshments{" "}
                      </th>
                      <td></td>
                    </tr>
                    <tbody className="seminartableborder">
                      <tr>
                        <td className="tg-9wq8" rowSpan={4}>
                          27th Sept. 2024
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          4.00 PM – 5.00 PM
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          Speaker Session (Talk & QnA)
                        </td>
                        <td
                          className="tg-9wq8 w-40 p-0"
                          rowSpan={4}
                          colSpan={2}>
                          <tr>
                            <td className="border-0">
                              Welcome & Introductory Address by Ar. Gutham Pawar{" "}
                            </td>

                            <td className="tg-9wq8 border-top-0 border-end-0">
                              <tr className="border-0">
                                <td className="w-50 border-0">
                                  Ar. Amit Parashar, M. Arch,. University of
                                  British Columbia, Product Design from
                                  Universita Delgi Studi di Firenze, Florence,
                                  Italy. Principal Architect, Design Workshop
                                  Goa. Subject: Environment & Desig
                                </td>
                              </tr>

                              {/* <tr>
                                  <td>Felicitation</td>
                                </tr>
                                <tr>
                                  <td>Photo Session</td>
                                </tr> */}
                            </td>
                          </tr>
                          <tr>
                            <td className="tg-9wq8 border-start-0 border-bottom-0">
                              Felicitation
                            </td>
                            <td className="tg-9wq8 border-start-0 border-bottom-0 border-end-0">
                              Ar. Amit Parashar
                            </td>
                          </tr>
                        </td>
                        {/* <td className="tg-9wq8 " rowSpan={4}>
                            <tr className="border-0">
                              <td className="border-0">                            Ms. Sandra Agnes D'Souza


                              </td>

                            </tr>
                            <tr >
                              <td className="border-top border-0">Ar. Amit Parashar

                              </td>
                            </tr>
                          </td> */}

                        <td
                          className="tg-9wq8 p-0 d-flex flex-column justify-content-between mt-auto border-0"
                          rowSpan={4}>
                          <tr className="">
                            <td className="p-0 border-0 py-4">
                              Ms. Sandra Agnes D'Souza
                            </td>
                          </tr>
                          <tr className="border-top w-100 border-dark text-center d-flex">
                            <td className="border-0 mx-auto w-100">
                              Ms. Sandra Agnes D'Souza <br /> and
                              <br /> Mr. Jivesh Sachdev
                            </td>
                          </tr>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>

                    {/* third */}
                    <tr>
                      <th className="text-center fs-5 fw-bold" colSpan={6}>
                        Day 2{" "}
                      </th>
                      <td></td>
                    </tr>
                    <tbody className="seminartableborder">
                      <tr>
                        <td className="tg-9wq8" rowSpan={4}>
                          28/9/2024{" "}
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          2:30 PM - 3: 00 PM
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          Speaker Session (Talk & QnA)
                        </td>
                        <td className="tg-9wq8 p-0" rowSpan={4} colSpan={2}>
                          <tr>
                            <td className="border-0">
                              Welcome & Introductory Address by Ar. Gutham Pawar{" "}
                            </td>

                            <td className="tg-9wq8 border-top-0 border-end-0">
                              <tr className="border-0">
                                <td className="border-0">
                                  Dr. Aniruddha Pawar, Ph.D., IIT Roorkee. Dean,
                                  Faculty of Architecture, Planning & Design,
                                  Goa University. Subject: Green Buildings,
                                  Climate Responsive Design
                                </td>
                              </tr>

                              {/* <tr>
                                  <td>Felicitation</td>
                                </tr>
                                <tr>
                                  <td>Photo Session</td>
                                </tr> */}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-start-0 border-bottom-0">
                              Felicitation
                            </td>
                            <td className="border-start-0 border-bottom-0 border-end-0">
                              Dr. Aniruddha Pawar
                            </td>
                          </tr>
                        </td>
                        {/* <td className="tg-9wq8 " rowSpan={4}>
                            <tr className="border-0">
                              <td className="border-0">                            Ms. Sandra Agnes D'Souza


                              </td>

                            </tr>
                            <tr >
                              <td className="border-top border-0">Ar. Amit Parashar

                              </td>
                            </tr>
                          </td> */}

                        <td className="tg-9wq8 p-0 d-flex flex-column justify-content-between mt-auto border-0 ">
                          <tr>
                            <td className="p-0 border-0 py-3">
                              Ms. Sandra Agnes D'Souza
                            </td>
                          </tr>
                          <tr className="border-top w-100 border-dark text-center d-flex">
                            <td className="border-0 mx-auto w-100">
                              Ms. Sandra Agnes D'Souza <br /> and
                              <br /> Mr. Jivesh Sachdev
                            </td>
                          </tr>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>

                    {/* fourth */}
                    <tr>
                      <th className="text-center fs-5 fw-bold" colSpan={6}>
                        Break for Refreshments{" "}
                      </th>
                      <th></th>
                    </tr>
                    {/* <tbody className="seminartableborder">
                        <tr rowSpan={4}>
                          <td className="tg-9wq8">28/9/2024</td>
                          <td className="tg-9wq8">28/9/2024</td>
                          <td className="tg-9wq8">28/9/2024</td>
                          <td className="tg-9wq8">28/9/2024</td>
                          <td className="tg-9wq8">28/9/2024</td>
                          <td className="tg-9wq8">28/9/2024</td>
                        </tr>
                      </tbody> */}

                    <tbody className="seminartableborder">
                      <tr>
                        <th rowspan="3">28/9/2024</th>
                        <td>3.30pm to 4.40pm</td>
                        <td>Speaker Session (Talk & QnA)</td>
                        <td>Welcome & Introductory Address</td>
                        <td>Monika Patel Architects India</td>
                        <td>Mr. Jivesh Sachdev</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>4:30 PM – 6: 00 PM</td>
                        <td>Workshops</td>
                        <td>Brief about JDSD followed by workshops</td>
                        <td>(Workshops yet to be curated)</td>
                        <td></td>
                        <td>Plan B</td>
                      </tr>
                      <tr>
                        <td>6:30 PM – 7: 00 PM</td>
                        <td>Prize distribution – Mr & Ms Hide Competition</td>
                        <td>Hosted by JDSD Student</td>
                        <td>Winners & Participants</td>
                        <td>Ms. Sandra Agnes D'Souza and Mr. Jivesh Sachdev</td>
                        <td></td>
                      </tr>
                    </tbody>

                    {/* fivth */}
                    <tr>
                      <th className="text-center fs-5 fw-bold" colSpan={6}>
                        Day 3{" "}
                      </th>
                      <th></th>
                    </tr>
                    <tbody className="seminartableborder">
                      <tr>
                        <td className="tg-9wq8" rowSpan={4}>
                          29/9/2024{" "}
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          11.00 AM – 1.00 PM
                        </td>
                        <td className="tg-9wq8" rowSpan={4}>
                          2:30 PM - 3: 00 PM
                        </td>
                        <td className="tg-9wq8 p-0" rowSpan={4} colSpan={2}>
                          <tr>
                            <td className="border-0"></td>

                            <td className="tg-9wq8 border-top-0">
                              <tr className="border-0">
                                <td className=" border-0">
                                  Speaker - Eco Furniture Recycle Bell
                                </td>
                              </tr>

                              {/* <tr>
                                  <td>Felicitation</td>
                                </tr>
                                <tr>
                                  <td>Photo Session</td>
                                </tr> */}
                            </td>
                            <tr className="">
                              <td className="border-start-0 border-bottom-0 border-top-0 p-3 border-end-0">
                                Speaker - Eco Furniture Recycle Bell
                              </td>
                            </tr>
                          </tr>
                          <tr>
                            <td className="border-start-0 border-bottom-0">
                              Felicitation
                            </td>
                            <td className="border-bottom-0">
                              Dr. Aniruddha Pawar
                            </td>
                            <td className="border-bottom-0 border-end-0">
                              Dr. Aniruddha Pawar
                            </td>
                          </tr>
                        </td>
                        {/* <td className="tg-9wq8 " rowSpan={4}>
                            <tr className="border-0">
                              <td className="border-0">                            Ms. Sandra Agnes D'Souza


                              </td>

                            </tr>
                            <tr >
                              <td className="border-top border-0">Ar. Amit Parashar

                              </td>
                            </tr>
                          </td> */}

                        <td
                          className="tg-9wq8 p-0 d-flex flex-column justify-content-between mt-auto border-0 "
                          rowSpan={4}>
                          <tr>
                            <td className="p-0 border-0 py-3">
                              {" "}
                              Ms. Sandra Agnes D'Souza
                            </td>
                          </tr>
                          <tr className="border-top w-100 border-dark text-center d-flex">
                            <td className="border-0 mx-auto w-100">
                              Ar. Amit Parashar
                            </td>
                          </tr>
                        </td>

                        <td></td>
                      </tr>
                    </tbody>
                    <tr>
                      <th className="text-center fs-5 fw-bold" colSpan={6}>
                        End of the Program{" "}
                      </th>
                      <th></th>
                    </tr>
                  </table>
                </div>
              </section>
              {/* <section className="webviewseminartable mt-4 mb-5 mx-0 px-0">
                  <div
                    style={{
                      overflowX: "auto",
                      tableLayout: "auto",
                      width: "100%",
                    }}
                  >
                    <table
                      className="tg tablewidthseminar"
                      style={{
                        overflowX: "auto",
                        tableLayout: "auto",                        
                      }}
                    >
                      <colgroup>
                        <col style={{ width: 110 }} />
                        <col style={{ width: 62 }} />
                        <col style={{ width: 188 }} />
                        <col style={{ width: 208 }} />
                        <col style={{ width: 420 }} />
                        <col style={{ width: 222 }} />
                      </colgroup>
                      <thead>
                        <tr className="thfontseminar">
                          <th className="tg-ioob text-white">Date &amp; Day</th>
                          <th className="tg-uwqm text-white">Select</th>
                          <th className="tg-ioob text-white">Time</th>
                          <th className="tg-ioob text-white">Speakers &amp; Panelist</th>
                          <th className="tg-3j1n text-white">Designation</th>
                          <th className="tg-ioob text-white">Topic</th>
                        </tr>
                      </thead>
                      <tbody className="seminartableborder">
                        <tr>
                          <td className="tg-9wq8" rowSpan={9}>
                            30th Sept. 2023 Friday
                          </td>
                          <td className="tg-9wq8" rowSpan={2}> */}
              {/* <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) =>
                                this.feedproductofinterest({
                                  e,
                                  cat: "Home Decor",
                                })
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            /> */}
              {/* </td>
                          <td className="tg-lboi" rowSpan={2}>
                            11.00 Am to 12.00 noon
                          </td>
                          <td className="tg-lboi"></td>
                          <td className="tg-0pky"></td>
                          <td className="tg-lboi">Inaugural</td>
                        </tr>
                        <tr>
                          <td className="tg-lboi">Ms.Neysa Vas</td>
                          <td className="tg-0pky">
                            Assistant Professor &amp; HoD, Fashion &amp;
                            Apparel Design, JD Institute of FashionTechnology,
                            Goa
                          </td>
                          <td className="tg-lboi">Event Host</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">
                            <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    seminarregistered1: "4.30 Pm to 5.00 Pm#Sustainability& it’s Practices in Interiors",
                                  })
                                } else {
                                  this.setState({
                                    seminarregistered1: "",
                                  })
                                }
                                
                              }}
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            />
                          </td>
                          <td className="tg-lboi">4.30 Pm to 5.00 Pm</td>
                          <td className="tg-lboi">Dr.Nischay Gowda</td>
                          <td className="tg-0pky">
                            HoD, InteriorDesign &amp; Decoration, JD Institute
                            of Fashion Technology, Bangalore
                          </td>
                          <td className="tg-lboi">
                            Sustainability&amp; it’s Practices in Interiors
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" rowSpan={6}>
                            <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    seminarregistered2: "5.00 Pm to 6.00 Pm#Panelist",
                                  })
                                } else {
                                  this.setState({
                                    seminarregistered2: "",
                                  })
                                }
                              }
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            />
                          </td>
                          <td className="tg-lboi" rowSpan={6}>
                            5.00 Pm to 6.00 Pm
                          </td>
                          <td className="tg-lboi">Ms.Sandra Agnes D Souza</td>
                          <td className="tg-0pky">
                            DirectorSouth, JD Institute of Fashion Technology,
                            Bangalore
                          </td>
                          <td className="tg-lboi" rowSpan={6}>
                            Panelist
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-lboi">Dr.Nischay Gowda</td>
                          <td className="tg-0pky">
                            HoD, InteriorDesign &amp; Decoration, JD Institute
                            of Fashion Technology, Bangalore
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-lboi">Sr.Ar.Tallulah Dsilva</td>
                          <td className="tg-0pky">
                            Sr. Architect, Goa Principle architect and founder
                            of Architecture t, sustainable greenarchitect,
                            educator, writer, TEDx speaker, and Environmentalist
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-lboi">Mr.Bruce Fernandes</td>
                          <td className="tg-0pky"></td>
                        </tr>
                        <tr>
                          <td className="tg-lboi">
                            Leed Ar. Chaitanya Gokhale
                          </td>
                          <td className="tg-0pky">Leed Ar. - Mozzaic</td>
                        </tr>
                        <tr>
                          <td className="tg-lboi">Mr.Adithya Joshi,</td>
                          <td className="tg-0pky">
                            ProductDesigner, Srishti School of Design
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" rowSpan={4}>
                            1st Oct. 2023 Saturday
                          </td>
                          <td className="tg-9wq8">
                            <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    seminarregistered3: "11.30 Am to12.30 Pm#“Rework &Refurb” UpcycledTextiles & Accessories in Interiors",
                                  })
                                } else {
                                  this.setState({
                                    seminarregistered3: "",
                                  })
                                }
                              }
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            />
                          </td>
                          <td className="tg-lboi">11.30 Am to12.30 Pm</td>
                          <td className="tg-lboi">
                            Dr.Shweta Mariyappanavar &amp; Ms.Rohitha. E.
                            Varghese
                          </td>
                          <td className="tg-0pky">
                            Vice Principal,JD Institute of Fashion Technology,
                            Goa &amp; Senior Designer, Contemporary Artist &amp;
                            Visiting Faculty, JD Institute ofFashion Technology,
                            Goa
                          </td>
                          <td className="tg-lboi">
                            “Rework &amp;Refurb” UpcycledTextiles &amp;
                            Accessories in Interiors
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">
                            <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    seminarregistered4: "3.30 Pm to 4.30Pm#CircularDesign",
                                  })
                                } else {
                                  this.setState({
                                    seminarregistered4: "",
                                  })
                                }
                              }
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            />
                          </td>
                          <td className="tg-lboi">3.30 Pm to 4.30Pm</td>
                          <td className="tg-lboi">
                            <span
                              style={{
                                color: "#1F1F1F",
                                backgroundColor: "white",
                              }}
                            >
                              Mr.Sanjiv Khandelwal,
                            </span>
                            Sensible Earth
                          </td>
                          <td className="tg-0pky">
                            Serial entrepreneur, early-stage investor, and
                            mentor to tech and tech-enabled startups, a pioneer
                            in the internet space building B2B marketplaces,
                            wireless networks, and dynamic pricing engines.
                            Founded Sensible. Earth, The Centre for
                            Sustainability, in Goa
                          </td>
                          <td className="tg-lboi">CircularDesign</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">
                            {/* <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) =>
                                this.feedproductofinterest({
                                  e,
                                  cat: "Home Decor",
                                })
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            /> */}
              {/* </td>
                          <td className="tg-lboi">4.30 Pm</td>
                          <td className="tg-lboi">
                            <span
                              style={{
                                color: "#1F1F1F",
                                backgroundColor: "white",
                              }}
                            >
                              Result Declaration
                            </span>
                          </td>
                          <td className="tg-0pky"></td>
                          <td className="tg-lboi"></td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8"> */}
              {/* <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) =>
                                this.feedproductofinterest({
                                  e,
                                  cat: "Home Decor",
                                })
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            /> */}
              {/* </td>
                          <td className="tg-lboi">6.00 Pm to 7.00Pm</td>
                          <td className="tg-lboi">
                            <span
                              style={{
                                color: "#1F1F1F",
                                backgroundColor: "white",
                              }}
                            >
                              Cultural Event
                            </span>
                          </td>
                          <td className="tg-0pky"></td>
                          <td className="tg-lboi"></td>
                        </tr>
                        <tr>
                          <td className="tg-nrix" rowSpan={2}>
                            2nd Oct. 2023 Sunday
                          </td>
                          <td className="tg-9wq8">
                            <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    seminarregistered5: "3.30 Pm to 4.30Pm#Hospitality and Interior Spaces",
                                  })
                                } else {
                                  this.setState({
                                    seminarregistered5: "",
                                  })
                                }
                              }
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            />
                          </td>
                          <td className="tg-cly1">11.30 Am to12.30 Pm</td>
                          <td className="tg-cly1">Mr. Vincent Ramos</td>
                          <td className="tg-0lax"></td>
                          <td className="tg-cly1">
                            Hospitality and Interior Spaces
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8"> */}
              {/* <input
                              class="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) =>
                                this.feedproductofinterest({
                                  e,
                                  cat: "Home Decor",
                                })
                              }
                              value="Lifestyle"
                              id="Decor-Lifestyle"
                            /> */}
              {/* </td>
                          <td className="tg-cly1"></td>
                          <td className="tg-cly1"></td>
                          <td className="tg-0lax"></td>
                          <td className="tg-cly1">
                            ValedictoryFunction and Prize Distribution
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
              {/* </section>  */}

              {/* phn view card */}

              <section className="phncardseminar mx-0 px-0">
                <div class="container-fluid mt-lg-3 mt-md-3 mt-sm-4 mt-4 comonseminarpadding">
                  {/* <div class="title host-card">
                      <h4 className="commonfontseminar mt-2">
                        Inauguration will be on{" "}
                        <span style={{ color: "rgb(247, 144, 76)" }}>
                          {" "}
                          30<sup>th</sup> Sept. 2023, Friday, 11:00 AM to 12:00
                          Noon{" "}
                        </span>
                      </h4>
                      <div className="datefontseminar mt-2">
                        {" "}
                        <div className="d-lg-flex d-block align-items-center">
                          <div className="col-lg-2 col-3">
                            <div className="speaker-img">
                              <img
                                src={Neysa}
                                alt="Speakers & Panelist"
                                className="w-100 img-fluid"
                              ></img>
                            </div>
                          </div>
                          <div className="col-lg-10 col-12">
                            <div className="ps-lg-3">
                              <h6 className="comonfontallseminar mt-3">
                                <b>Event Host :</b>
                              </h6>
                              <p className=" mb-2">Ms. Neysa Vas</p>
                              <h6 className="comonfontallseminar">
                                <b>Designation :</b>
                              </h6>

                              <div
                                className="col-12 comonfontallseminar"
                                style={{ textAlign: "justify" }}
                              >
                                Assistant Professor &amp; HOD, Fashion &amp;
                                Apparel Design, JD Institute of
                                FashionTechnology, Goa
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div
                        className="boxseminar1 rounded-3"
                        style={{ marginTop: "5px" }}>
                        <div className="row">
                          <div className="col-lg-6 mb-lg-2">
                            <h5>
                              <strong className="timesemianrcommon">
                                Date - 27<sup>th</sup> Sept. 2024{" "}
                              </strong>
                            </h5>
                          </div>
                          <div className="col-lg-6 text-lg-end order-1 order-lg-0 order-lg-0 mt-lg-0 mt-2">
                            <div class="form-check form-check-inline">
                              <label
                                class="form-check-label"
                                for="inlineCheckbox1">
                                {/* <b className="comonfontallseminar">
                                    {" "}
                                    I am interested to attend this seminar
                                  </b> */}
                              </label>
                              {/* <input
                                  class="form-check-input mt-2"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="option1"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        seminarregistered1:
                                          "4.30 PM to 5.00 PM#Sustainability & it’s Practices in Interiors#30th September 2023",
                                      });
                                    } else {
                                      this.setState({
                                        seminarregistered1: "",
                                      });
                                    }
                                  }}
                                /> */}
                            </div>
                          </div>
                          <div className="col-12">
                            <h5>
                              <strong className="timesemianrcommon">
                                Time - 4:00 PM to 5:00 PM{" "}
                              </strong>
                            </h5>
                          </div>

                          <div className="col-12 mt-3">
                            <div className="row">
                              <div className="col-12">
                                {/* <h6 className="comonfontallseminar">
                                    <b>Seminar Topic :</b>
                                  </h6> */}
                              </div>

                              {/* <div className="col-12 mt-2 comonfontallseminar">
                                  Sustainability & it’s Practices in Interiors
                                </div> */}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row mt-3">
                              <div className="">
                                <div className="mt-2 comonfontallseminar">
                                  <div className="d-lg-flex d-block align-items-center">
                                    <div className="col-lg-2 col-3">
                                      <div className="speaker-img">
                                        <img
                                          src={Amit}
                                          alt="Speakers & Panelist"
                                          className="w-100 img-fluid"></img>
                                      </div>
                                    </div>
                                    <div className="col-lg-10 col-12">
                                      <div className="ps-lg-3">
                                        <p className=" mb-2">
                                          <b className="text-dark">
                                            {" "}
                                            Ar. Amit Parashar
                                          </b>
                                        </p>
                                        <h6 className="comonfontallseminar">
                                          <b>Qualifications :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}>
                                          M. Arch, University of British
                                          Columbia; Product Design, Università
                                          degli Studi di Firenze, Florence,
                                          Italy
                                        </div>
                                        <h6 className="comonfontallseminar">
                                          <b>Position :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}>
                                          Principal Architect, Design Workshop
                                          Goa
                                        </div>
                                        <h6 className="comonfontallseminar">
                                          <b>Subject :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}>
                                          Environment & Design
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="boxseminar1 rounded-3">
                        <div className="row">
                          <div className="col-lg-6 mb-lg-2">
                            <h5>
                              <strong className="timesemianrcommon">
                                Date - 28<sup>th</sup> Sept. 2024{" "}
                              </strong>
                            </h5>
                          </div>

                          <div className="col-12">
                            <h5>
                              <strong className="timesemianrcommon">
                                Time - 02:30 PM - 03:00 PM{" "}
                              </strong>
                            </h5>
                          </div>

                          <div className="col-12 mt-3">
                            <div className="row">
                              <div className="col-12">
                                {/* <h6 className="">
                                    <b>Seminar Topic :</b>
                                  </h6> */}
                              </div>

                              {/* <div className="col-12 comonfontallseminar mt-2">
                                  Sustainability & its Practices in Interiors
                                  Panelist
                                </div> */}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row mt-3">
                              <div className="col-12 mt-2">
                                <div className="row">
                                  <div className="mt-2 comonfontallseminar">
                                    <div className="d-lg-flex d-block align-items-center">
                                      <div className="col-lg-2 col-3">
                                        <div className="speaker-img">
                                          <img
                                            src={Aniruddha}
                                            alt="Speakers & Panelist"
                                            className="w-100 img-fluid"></img>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 col-12">
                                        <div className="ps-lg-3">
                                          <p className=" mb-2">
                                            {" "}
                                            <b className="text-dark">
                                              Dr. Aniruddha Pawar
                                            </b>
                                          </p>
                                          <h6 className="comonfontallseminar">
                                            <b>Qualifications :</b>
                                          </h6>
                                          <div
                                            className="col-12 comonfontallseminar"
                                            style={{ textAlign: "justify" }}>
                                            Ph.D., IIT Roorkee
                                          </div>
                                          <h6 className="comonfontallseminar">
                                            <b>Position :</b>
                                          </h6>
                                          <div
                                            className="col-12 comonfontallseminar"
                                            style={{ textAlign: "justify" }}>
                                            Dean, Faculty of Architecture,
                                            Planning & Design, Goa University
                                          </div>
                                          <h6 className="comonfontallseminar">
                                            <b>Subject :</b>
                                          </h6>
                                          <div
                                            className="col-12 comonfontallseminar"
                                            style={{ textAlign: "justify" }}>
                                            Green Buildings, Climate Responsive
                                            Design
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row mt-3">
                                    <div className="mt-2 comonfontallseminar">
                                      <div className="d-lg-flex d-block align-items-center">
                                        <div className="col-lg-2 col-3">
                                          <div className="speaker-img">
                                            <img
                                              src={Nischay_Gowda}
                                              alt="Speakers & Panelist"
                                              className="w-100 img-fluid"
                                            ></img>
                                          </div>
                                        </div>
                                        <div className="col-lg-10 col-12">
                                          <div className="ps-lg-3">
                                            <p className=" mb-2">
                                              {" "}
                                              Dr. Nischay Gowda
                                            </p>
                                            <h6 className="comonfontallseminar">
                                              <b>Designation :</b>
                                            </h6>
                                            <div
                                              className="col-12 comonfontallseminar"
                                              style={{ textAlign: "justify" }}
                                            >
                                              HOD, Interior Design & Decoration,
                                              JD Institute of Fashion
                                              Technology, Bangalore
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="mt-2 comonfontallseminar">
                                      <div className="d-lg-flex d-block align-items-center">
                                        <div className="col-lg-2 col-3">
                                          <div className="speaker-img">
                                            <img
                                              src={Tallulah}
                                              alt="Speakers & Panelist"
                                              className="w-100 img-fluid"
                                            ></img>
                                          </div>
                                        </div>
                                        <div className="col-lg-10 col-12">
                                          <div className="ps-lg-3">
                                            <p className=" mb-2">
                                              {" "}
                                              Sr. Ar. Tallulah Dsilva
                                            </p>
                                            <h6 className="comonfontallseminar">
                                              <b>Designation :</b>
                                            </h6>
                                            <div
                                              className="col-12 comonfontallseminar"
                                              style={{ textAlign: "justify" }}
                                            >
                                              Sr. Architect, Goa Principle
                                              Architect and Founder of
                                              Architecture, Sustainable Green
                                              Architect, Educator, Writer, TEDx
                                              Speaker, and Environmentalist
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="mt-2 comonfontallseminar">
                                      <div className="d-lg-flex d-block align-items-center">
                                        <div className="col-lg-2 col-3">
                                          <div className="speaker-img">
                                            <img
                                              src={Bruce}
                                              alt="Speakers & Panelist"
                                              className="w-100 img-fluid"
                                            ></img>
                                          </div>
                                        </div>
                                        <div className="col-lg-10 col-12">
                                          <div className="ps-lg-3">
                                            <p className=" mb-2">
                                              {" "}
                                              Mr. Bruce Fernandes
                                            </p>
                                            <h6 className="comonfontallseminar">
                                              <b>Designation :</b>
                                            </h6>
                                            <div
                                              className="col-12 comonfontallseminar"
                                              style={{ textAlign: "justify" }}
                                            >
                                              Interior Designer, BAFID
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                              </div>
                            </div>
                          </div>

                          {/* <div className="row mt-3">
                              <div className="mt-2 comonfontallseminar">
                                <div className="d-lg-flex d-block align-items-center">
                                  <div className="col-lg-2 col-3">
                                    <div className="speaker-img">
                                      <img
                                        src={Chaitanya_Gokhale_Photo}
                                        alt="Speakers & Panelist"
                                        className="w-100 img-fluid"
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-lg-10 col-12">
                                    <div className="ps-lg-3">
                                      <p className=" mb-2">
                                        {" "}
                                        Leed Ar. Chaitanya Gokhale
                                      </p>
                                      <h6 className="comonfontallseminar">
                                        <b>Designation :</b>
                                      </h6>
                                      <div
                                        className="col-12 comonfontallseminar"
                                        style={{ textAlign: "justify" }}
                                      >
                                        Architect, Mozaic
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="mt-2 comonfontallseminar">
                                <div className="d-lg-flex d-block align-items-center">
                                  <div className="col-lg-2 col-3">
                                    <div className="speaker-img">
                                      <img
                                        src={ajoshi}
                                        alt="Speakers & Panelist"
                                        className="w-100 img-fluid"
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="col-lg-10 col-12">
                                    <div className="ps-lg-3">
                                      <p className=" mb-2">
                                        {" "}
                                        Mr. Adithya Joshi
                                      </p>
                                      <h6 className="comonfontallseminar">
                                        <b>Designation :</b>
                                      </h6>
                                      <div
                                        className="col-12 comonfontallseminar"
                                        style={{ textAlign: "justify" }}
                                      >
                                        Product Designer, Maker and Educator
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="container-fluid mt-2 comonseminarpadding">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div
                          className="boxseminar2 rounded-3"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="row">
                            <div className="col-lg-6 mb-lg-2">
                              <h5>
                                <strong className="timesemianrcommon">
                                  Date - 1<sup>st</sup> Oct. 2023, Saturday{" "}
                                </strong>
                              </h5>
                            </div>
                            <div className="col-lg-6 text-lg-end order-1 order-lg-0 mt-lg-0 mt-2">
                              <div class="form-check form-check-inline">
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox1"
                                >
                                  <b className="comonfontallseminar">
                                    {" "}
                                    I am interested to attend this seminar
                                  </b>
                                </label>
                                <input
                                  class="form-check-input mt-2"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="option1"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        seminarregistered3:
                                          "11.30 AM to12.30 PM#“Rework & Refurb” UpcycledTextiles & Accessories in Interiors#1st October 2023",
                                      });
                                    } else {
                                      this.setState({
                                        seminarregistered3: "",
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <h5>
                                <strong className="timesemianrcommon">
                                  Time - 11:30 AM to 12:30 PM{" "}
                                </strong>
                              </h5>
                            </div>

                            <div className="col-12 mt-3">
                              <div className="row">
                                <div className="col-12">
                                  <h6>
                                    <b className="comonfontallseminar">
                                      Seminar Topic :
                                    </b>
                                  </h6>
                                </div>

                                <div className="col-12 mt-2 comonfontallseminar">
                                  “Rework & Refurb” Upcycled Textiles &
                                  Accessories in Interiors
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="row mt-3">
                                <div className="mt-2 comonfontallseminar">
                                  <div className="d-lg-flex d-block align-items-center">
                                    <div className="col-lg-2 col-3">
                                      <div className="speaker-img">
                                        <img
                                          src={Shweta_Mariyappanavar}
                                          alt="Speakers & Panelist"
                                          className="w-100 img-fluid"
                                        ></img>
                                      </div>
                                    </div>
                                    <div className="col-lg-10 col-12">
                                      <div className="ps-lg-3">
                                        <p className=" mb-2">
                                          {" "}
                                          Dr. Shweta Mariyappanavar
                                        </p>
                                        <h6 className="comonfontallseminar">
                                          <b>Designation :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}
                                        >
                                          Vice Principal, JD Institute of
                                          Fashion Technology, Goa
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="row mt-3">
                                <div className="mt-2 comonfontallseminar">
                                  <div className="d-lg-flex d-block align-items-center">
                                    <div className="col-lg-2 col-3">
                                      <div className="speaker-img">
                                        <img
                                          src={Rohitha}
                                          alt="Speakers & Panelist"
                                          className="w-100 img-fluid"
                                        ></img>
                                      </div>
                                    </div>
                                    <div className="col-lg-10 col-12">
                                      <div className="ps-lg-3">
                                        <p className=" mb-2">
                                          Ms. Rohitha. E. Varghese
                                        </p>
                                        <h6 className="comonfontallseminar">
                                          <b>Designation :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}
                                        >
                                          Founder - Kriyations.in, Senior
                                          Designer, Contemporary Artist &
                                          Faculty, JD Institute of Fashion
                                          Technology, Goa
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="boxseminar3 rounded-3">
                          <div className="row">
                            <div className="col-lg-6 mb-lg-2">
                              <h5>
                                <strong className="timesemianrcommon">
                                  Date - 1<sup>st</sup> Oct. 2023, Saturday{" "}
                                </strong>
                              </h5>
                            </div>
                            <div className="col-lg-6 text-lg-end order-1 order-lg-0 mt-lg-0 mt-2">
                              <div class="form-check form-check-inline">
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox1"
                                >
                                  <b className="comonfontallseminar">
                                    {" "}
                                    I am interested to attend this seminar
                                  </b>
                                </label>
                                <input
                                  class="form-check-input mt-2"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="option1"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        seminarregistered4:
                                          "3:30 PM to 4:30PM#Circular Design#1st October 2023",
                                      });
                                    } else {
                                      this.setState({
                                        seminarregistered4: "",
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <h5>
                                <strong className="timesemianrcommon">
                                  Time - 3:30 PM to 4:30 PM{" "}
                                </strong>
                              </h5>
                            </div>

                            <div className="col-12 mt-3">
                              <div className="row">
                                <div className="col-12">
                                  <h6>
                                    <b className="comonfontallseminar">
                                      Seminar Topic :
                                    </b>
                                  </h6>
                                </div>

                                <div className="col-12 mt-2 comonfontallseminar">
                                  Circular Design
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="row mt-3">
                                <div className="mt-2 comonfontallseminar">
                                  <div className="d-lg-flex d-block align-items-center">
                                    <div className="col-lg-2 col-3">
                                      <div
                                        className="speaker-img"
                                        style={{ height: "auto" }}
                                      >
                                        <img
                                          src={Sanjiv_Khandelwal}
                                          alt="Speakers & Panelist"
                                          className="w-100 img-fluid"
                                        ></img>
                                      </div>
                                    </div>
                                    <div className="col-lg-10 col-12">
                                      <div className="ps-lg-3">
                                        <p className=" mb-2">
                                          {" "}
                                          Mr. Sanjiv Khandelwal, Sensible Earth
                                        </p>
                                        <h6 className="comonfontallseminar">
                                          <b>Designation :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}
                                        >
                                          Investor, and Mentor to Tech and
                                          Tech-enabled Startups, a Pioneer in
                                          the internet space building B2B
                                          marketplaces, Founder- Sensible Earth.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                {/* <div class="container-fluid mt-2 comonseminarpadding">
                    <div class="title host-card">
                      <h5 className="mt-2 commonfontseminar">
                        Result Declaration will be held on{" "}
                        <span style={{ color: "rgb(247, 144, 76)" }}>
                          1<sup>st</sup> Oct.2023, Saturday at 4:30 PM
                        </span>
                      </h5>

                      <h5 className="mt-2 commonfontseminar">
                        Cultural Event from{" "}
                        <span style={{ color: "rgb(247, 144, 76)" }}>
                          6:00 PM to 7:00 PM
                        </span>
                      </h5>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div
                          className="boxseminar4 rounded-3"
                          style={{ marginTop: "5px" }}
                        >
                          <div className="row">
                            <div className="col-6 mb-2">
                              <h5>
                                <strong className="timesemianrcommon">
                                  Date - 2<sup>nd</sup> Oct.2023, Sunday{" "}
                                </strong>
                              </h5>
                            </div>
                            <div className="col-lg-6 text-lg-end order-1 order-lg-0 mt-lg-0 mt-2">
                              <div class="form-check form-check-inline">
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox1"
                                >
                                  <b className="comonfontallseminar">
                                    {" "}
                                    I am interested to attend this seminar
                                  </b>
                                </label>
                                <input
                                  class="form-check-input mt-2"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="option1"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        seminarregistered5:
                                          "3:30 PM to 4:30PM#Hospitality and Interior Spaces#2nd October 2023",
                                      });
                                    } else {
                                      this.setState({
                                        seminarregistered5: "",
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <h5>
                                <strong className="timesemianrcommon">
                                  Time - 11:30 AM to 12:30 PM{" "}
                                </strong>
                              </h5>
                            </div>

                            <div className="col-12 mt-3">
                              <div className="row">
                                <div className="col-12">
                                  <h6>
                                    <b className="comonfontallseminar">
                                      Seminar Topic :
                                    </b>
                                  </h6>
                                </div>

                                <div className="col-12 mt-2 comonfontallseminar">
                                  Hospitality and Interior Spaces
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="row mt-3">
                                <div className="mt-2 comonfontallseminar">
                                  <div className="d-lg-flex d-block align-items-center">
                                    <div className="col-lg-2 col-3">
                                      <div
                                        className="speaker-img"
                                        style={{ height: "auto" }}
                                      >
                                        <img
                                          src={Vincent}
                                          alt="Speakers & Panelist"
                                          className="w-100 img-fluid"
                                        ></img>
                                      </div>
                                    </div>
                                    <div className="col-lg-10 col-12">
                                      <div className="ps-lg-3">
                                        <p className=" mb-2">
                                          {" "}
                                          Mr. Vincent Ramos
                                        </p>
                                        <h6 className="comonfontallseminar">
                                          <b>Designation :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}
                                        >
                                          Sr. Vice President, IHCL- Taj Hotels,
                                          Goa
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="title host-card">
                          <h5 className="commonfontseminar">
                            Valedictory Function and Prize Distribution will be held on{" "}
                            <span style={{ color: "rgb(247, 144, 76)" }}>
                              2<sup>nd</sup> Oct.2023, Sunday, from 11:30 AM to 12:30
                              PM
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </section>
              <div className="container-fluid mt-3 mb-5 p-0 mx-0">
                {/* <div
                    className="row m-0 py-2 px-2 rounded-2 text-white mainparentseminar"
                    style={{ backgroundColor: "#f7904c" }}
                  >
                    <h4 className="col-auto seminarvisitorheading">
                      Seminar Registration Form

                    </h4>

                  </div> */}
                {/* button */}
                {/* <div className="stall_button stall_button-top-nav">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSewDmVxNJ5GAk9CUxz9DHe7o90Jpqkkn2J_ACkhQIU1PnM65w/viewform?fbclid=PAZXh0bgNhZW0CMTEAAaapHnh9qmzzMNqTCdqRF06D8uWdBeE2ZForAqlzqP3WlPTqIjeVyJRfYUo_aem_FS-PEHECciDk3CUBCDZMlw" target="_blank">
                      Book Your Slot for Seminar
                    </a>
                  </div> */}

                {/* <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSewDmVxNJ5GAk9CUxz9DHe7o90Jpqkkn2J_ACkhQIU1[…]uWdBeE2ZForAqlzqP3WlPTqIjeVyJRfYUo_aem_FS-PEHECciDk3CUBCDZMlw"
                    width="640"
                    height="100"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0">
                    Loading…
                  </iframe> */}

                {/* <div class="row g-3 mt-3">
                    <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                      <label for="name" class="form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                      <label for="designation" class="form-label">
                        Designation
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="designation"
                        value={this.state.designation}
                        onChange={(e) =>
                          this.setState({ designation: e.target.value })
                        }
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                      <label for="company" class="form-label">
                        Company
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company"
                        value={this.state.company}
                        onChange={(e) =>
                          this.setState({ company: e.target.value })
                        }
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                      <label for="number" class="form-label">
                        Mobile Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="number"
                        value={this.state.mobile}
                        onChange={(e) =>
                          this.setState({ mobile: e.target.value })
                        }
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                      <label for="email" class="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        value={this.state.emailid}
                        onChange={(e) =>
                          this.setState({ emailid: e.target.value })
                        }
                      />
                    </div>

                    <div class="col-12">
                      <button
                        class="btn btn-danger download"
                        onClick={() => {
                          if (this.state.name != "") {
                            if (this.state.mobile != "") {
                              if (this.state.emailid != "") {
                                this.addVisitor();
                              } else {
                                Notiflix.Notify.failure(
                                  "Please enter your Email"
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Please enter your mobile number"
                              );
                            }
                          } else {
                            Notiflix.Notify.failure("Please enter your name");
                          }
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
