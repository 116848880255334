import React, { useEffect, useState } from "react";
import "swiper/css";

import Notiflix from "notiflix";
import GetApiCall from "../API/GETAPI";
import PostApiCall from "../API/POSTAPI";
import "./Showdirectory.css";
const DirectoryList = () => {
  const [List, setList] = useState([]);
  const [viewDetail, setviewDetail] = useState(null);
  const [message, setMessage] = useState("");
  var customerdata = localStorage.getItem("CustomData");
  var customerdetails = JSON.parse(customerdata);

  React.useEffect(() => {
    if (window.innerWidth > 500) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    GetApiCall.getRequest("getExhibitorData").then((resultscity) =>
      resultscity.json().then((obj) => {
        if (resultscity.status == 200 || resultscity.status == 201) {
          console.log(obj.data);
          setList(obj.data);
        }

        Notiflix.Loading.remove();
      })
    );
  }, []);

  return (
    <>
      <div className="title" style={{ marginBottom: "100px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mb-4">
              <h1 className=" mt-1">Show Directory</h1>
            </div>
            {List.map((item) => {
              return (
                <div className="col-12 mb-4">
                  <div
                    class="directory-card"
                    // style={{
                    //   boxShadow: "0 2px 5px rgb(162 169 204 / 24%)",
                    //   borderTop: "3px solid #f7904c",
                    // }}
                  >
                    <div className="m-0">
                      <div
                        className="image position-relative"
                        onClick={() => setviewDetail(item)}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom"
                        aria-controls="offcanvasBottom">
                        <img
                          src={
                            item
                              ? JSON.parse(item.exhibitor_Images)[0]
                                  ?.fld_imageurl
                              : ""
                          }
                          className="img-fluid"
                          alt=""
                        />
                        <div className="btn text-white col-auto d-flex booth-no">
                          <span className=" me-1">Stall No.</span>
                          <span>{item.fld_stallNo}</span>
                        </div>
                      </div>

                      <div
                        className="p-3"
                        onClick={() => setviewDetail(item)}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom"
                        aria-controls="offcanvasBottom">
                        <h6
                          className="d-flex align-items-center fw-bold"
                          style={{ fontSize: "16px", fontWeight: "400" }}>
                          {item.fld_companyName}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex mt-1 card-links justify-content-between">
                      <span
                        className="view-btn"
                        onClick={() => setviewDetail(item)}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom"
                        aria-controls="offcanvasBottom">
                        View Details
                      </span>
                      <div className="d-flex align-items-center">
                        <a
                          href={`tel:${item.fld_contactPersonMobile}`}
                          style={{ color: "inherit" }}>
                          <i
                            class="bi bi-telephone-outbound-fill"
                            style={{ fontSize: "30px" }}></i>
                        </a>
                        <a
                          href={`mailto:${
                            item.fld_email == null ? "" : `${item.fld_email}`
                          }`}
                          style={{ color: "inherit" }}>
                          <i
                            class="bi bi-envelope-fill"
                            style={{ fontSize: "30px" }}></i>
                        </a>
                        <a
                          href={`https://wa.me/${item.fld_contactNo}`}
                          style={{ color: "inherit" }}>
                          <i
                            class="bi bi-whatsapp"
                            style={{ fontSize: "30px" }}></i>
                        </a>
                        {item.fld_website != null && (
                          <a
                            href={item.fld_website}
                            target="_blank"
                            style={{ color: "inherit" }}>
                            <i
                              class="bi bi-globe2"
                              style={{ fontSize: "30px" }}></i>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="card-canvas">
          <div
            class="offcanvas offcanvas-bottom"
            tabindex="-1"
            id="offcanvasBottom"
            aria-labelledby="offcanvasBottomLabel">
            <div class="offcanvas-header ">
              <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                Booth No. {viewDetail?.fld_stallNo} <br />{" "}
                {viewDetail?.fld_companyName}{" "}
              </h5>
              <i
                class="bi bi-x-circle-fill close-card-btn"
                data-bs-dismiss="offcanvas"
                aria-label="Close"></i>
            </div>

            <div class="offcanvas-body small" style={{ marginBottom: "70px" }}>
              <div className="row m-0">
                {viewDetail?.fld_images?.split("#").map((val, i) => {
                  return (
                    <div
                      className={`col-6 p-0 ${i % 2 == 0 ? "pe-1" : "ps-1"}`}>
                      <img
                        src={val}
                        width="100%"
                        height="150px"
                        className="img-responsive"
                      />
                    </div>
                  );
                })}
              </div>

              <h6 className="mt-3">
                <span className="fw-bold">Contact Person</span>:{" "}
                {viewDetail?.fld_contactPerson}
              </h6>
              <h6 className="mt-2 fw-bold">Address </h6>
              <h6
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "5px",
                }}>
                {viewDetail?.fld_address}
                {/* <br /> */}
                {/* Near Balaji Hotel Pisoli Village, Taluka Haweli,<br /> */}
                {/* {viewDetail?.fld_city} - {viewDetail?.fld_pincode}{" "}
                                {viewDetail?.fld_country}. */}
              </h6>
              <h6 className="mt-2 fw-bold">Mobile Number</h6>
              {/* <h6 style={{ fontSize: "16px", fontWeight: "400", marginTop: "5px", }}>+91 74988 85130</h6> */}
              {viewDetail?.fld_contactNo != null && (
                <h6
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "5px",
                  }}>
                  <a href={`tel:${viewDetail?.fld_contactNo}`}>
                    {viewDetail?.fld_contactNo}
                  </a>
                </h6>
              )}
              {/* {viewDetail?.fld_mobile2 != null && (
                                <h6
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        marginTop: "5px",
                                    }}>
                                    <a href={`tel:${viewDetail?.fld_mobile3}`}>
                                        {viewDetail?.fld_mobile2}
                                    </a>
                                </h6>
                            )} */}
              {viewDetail?.fld_contactPersonMobile != null && (
                <h6
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "5px",
                  }}>
                  <a href={`tel:${viewDetail?.fld_contactPersonMobile}`}>
                    {viewDetail?.fld_contactPersonMobile}
                  </a>
                </h6>
              )}

              <h6 className="mt-2 fw-bold">E-mail</h6>

              {viewDetail?.fld_email != null && (
                <h6
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "5px",
                  }}>
                  <a href={`mailto:${viewDetail?.fld_email}`}>
                    {viewDetail?.fld_email}
                  </a>
                </h6>
              )}
              {viewDetail?.fld_companyDescription && (
                <>
                  <h6 className="mt-2 fw-bold">Company / Product Profile</h6>
                  <h6
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      marginTop: "5px",
                      textAlign: "justify",
                    }}>
                    {viewDetail?.fld_companyDescription}
                  </h6>
                </>
              )}
              <div className="directory-image-box">
                {viewDetail
                  ? JSON.parse(viewDetail.exhibitor_Images)?.map((data) => {
                      return (
                        <img
                          src={data.fld_imageurl}
                          className="img-fluid"
                          alt=""
                        />
                      );
                    })
                  : ""}
              </div>
              <div className="d-flex flex-column my-3 px-2 drawer-links border-top pt-3 justify-content-between">
                <a href={`tel:${viewDetail?.fld_contactPersonMobile}`}>
                  <i
                    class="bi bi-telephone-outbound-fill"
                    style={{ fontSize: "30px" }}></i>
                  {viewDetail?.fld_contactPersonMobile}
                </a>
                <a
                  href={`mailto:${
                    viewDetail?.fld_email == null
                      ? ""
                      : `${viewDetail?.fld_email};`
                  }`}
                  style={{ color: "inherit" }}>
                  <i
                    class="bi bi-envelope-fill"
                    style={{ fontSize: "30px" }}></i>
                  {viewDetail?.fld_email == null
                    ? ""
                    : `${viewDetail?.fld_email}`}
                </a>
                {viewDetail?.fld_contactNo && (
                  <a
                    href={`https://wa.me/${viewDetail?.fld_contactNo}`}
                    style={{ color: "inherit" }}>
                    <i class="bi bi-whatsapp" style={{ fontSize: "30px" }}></i>
                    {viewDetail?.fld_contactNo}
                  </a>
                )}
                {viewDetail?.fld_website != null && (
                  <a href={viewDetail?.fld_website} target="_blank">
                    <i class="bi bi-globe2" style={{ fontSize: "30px" }}></i>
                    {viewDetail?.fld_website}
                  </a>
                )}
                <textarea
                  placeholder="Send a message"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <button
                  className="btn btn-danger download"
                  onClick={() => {
                    PostApiCall.postRequest(
                      {
                        email: "manmeet@globaltrendz.com",
                        message: message,
                        fromemail: customerdetails[0]?.email,
                        frommobile: customerdetails[0]?.mobile,
                        fromname: customerdetails[0]?.name,
                      },
                      "VisitorEnquiryMailer"
                    ).then((resultscity) =>
                      resultscity.json().then((obj) => {
                        if (
                          resultscity.status == 200 ||
                          resultscity.status == 201
                        ) {
                          console.log(obj.data);
                        }

                        Notiflix.Loading.remove();
                      })
                    );
                  }}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectoryList;
