
import React from 'react';
import Category from '../Comp/Category';
import Footer from '../Comp/Footer'
import Header from '../Comp/Header';
import Video from '../assets/video/lighting.mp4';
import ReactPlayer from "react-player/lazy";
import { Helmet } from 'react-helmet';

const Lightingzone = () => {
    return (
        <>
            <Helmet>
                <title>Lighting Zone Exhibition | Exhibition Organizer | Hide India Expo 2024 </title>
                <meta name="description"
                    content="Lighting is one of the most important design elements, but it’s often also one of the most underrated." />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2024,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/Lighting-zone" />
                <meta property="og:title" content="Lighting Zone Exhibition | Exhibition Organizer | Hide India Expo 2024 " />
                <meta property="og:description" content="Lighting is one of the most important design elements, but it’s often also one of the most underrated." />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2024" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/lightning_zone.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/Lighting-zone" />
                <meta property="twitter:title" content="Lighting Zone Exhibition | Exhibition Organizer | Hide India Expo 2024 " />
                <meta property="twitter:description" content="Lighting is one of the most important design elements, but it’s often also one of the most underrated." />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2024,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/lightning_zone.webp" />
                <link rel="canonical" href="https://hideindia.com/lighting-zone" />
            </Helmet>
            <Header />

            <section class="slider ">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/lightning_zone.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/lightningzone.png.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext" id="banner_text_inner">
                            <h1>Lighting Zone</h1>


                            <p class="bannerp">Ambeint lighting forms the key to decor for any
                                establishment
                                in <br />the Hospitality Industry. Find the latest lighting
                                solutions from <br />across the globe to transform the ambience of your
                                establishment <br /> at HIDE INDIA 2024
                            </p>
                        </div>

                    </div>
                </div>
            </section>



            <Category />


            <section class="furnituresection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 className='mb-2'>Lighting Zone</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p className='mt-2'>
                                Where you’ll find the latest lighting solutions from across the globe to transform the ambience
                                of your establishment.<br />
                                Lighting is one of the most important design elements, but it’s often also one of the most
                                underrated. The lighting in
                                an establishment affects many aspects of the customer experience, and can set the correct mood,
                                increase sales and help
                                differentiate space within your establishment.<br />
                                We want to help you ensure that your business is lit in a way that maximises its potential.
                                That’s why we’ve scoured the
                                globe and crammed into one zone not only the most stylish lighting solutions available, but also
                                a whole host of experts
                                on the subject. Housing everything from accent lighting and wall sconces to display lights and
                                recessed lighting, you’ll
                                find all the latest lighting solutions you could possibly wish for.<br />
                                <b><i>Lighting Zone</i></b> will exhibit the latest trends and designs of Lights & Fittings for
                                the Hospitality industry at <b>HIDE 2024</b>
                            </p>
                        </div>
                        <div class="col-md-5">
                            <ReactPlayer
                                url={Video}
                                playing
                                loop={true}
                                autoplay
                                width="100%"
                                height="100%"
                            />

                        </div>
                    </div>
                </div>
            </section>










            <Footer />

        </>
    )
}

export default Lightingzone