
import React from 'react'
import Category from '../../Comp/Category'
import Footer from '../../Comp/Footer'
import Header from '../../Comp/Header'
// import imagebanner from '../assets/banner/whoshouldvisit.webp'
import image from '../../assets/banner/visitor.webp'
import { Helmet } from 'react-helmet'

const Visitor = () => {
    return (
        <>
            <Helmet>
                <title>Visitor | Hospitality Interior & Design Expo 2024 | HIDE India </title>
                <meta name="description"
                    content="People who can visit our Expo - Hospitality Business Owners, Interior Designers, Heads of Design, Creative Directors, Brand Managers, Heads of Marketing, Graphic Designers, Directors, CEO’s, Business Development Directors, Architects" />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2024, Creative Directors, Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/visitor" />
                <meta property="og:title" content="Visitor | Hospitality Interior & Design Expo 2024 | HIDE India " />
                <meta property="og:description" content="People who can visit our Expo - Hospitality Business Owners, Interior Designers, Heads of Design, Creative Directors, Brand Managers, Heads of Marketing, Graphic Designers, Directors, CEO’s, Business Development Directors, Architects" />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2024" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/whoshouldvisit.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/visitor" />
                <meta property="twitter:title" content="Visitor | Hospitality Interior & Design Expo 2024 | HIDE India " />
                <meta property="twitter:description" content="People who can visit our Expo - Hospitality Business Owners, Interior Designers, Heads of Design, Creative Directors, Brand Managers, Heads of Marketing, Graphic Designers, Directors, CEO’s, Business Development Directors, Architects" />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2024, Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/whoshouldvisit.webp" />
                <link rel="canonical" href="https://hideindia.com/visitor" />
            </Helmet>

            <Header />

            <section class="slider ">
                <div class="container-fluid p-0">

                    <img src='/assets/images/inner-banner/whoshouldvisit.webp' alt='' class="bannerimg2 forwebsite" />

                    <img src='/assets/images/banner/mobilebanner/whoshouldvisit.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext">

                        </div>

                    </div>
                </div>
            </section>

            <section class="aboutusection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 style={{ marginBottom: "10px" }} >Who Should Visit</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p style={{ marginTop: "1em" }} >

                                <div class="aboutlist">
                                    <ul>
                                        <li>&nbsp;Hospitality Business Owners</li>
                                        <li>&nbsp;Interior Designers</li>
                                        <li>&nbsp;Heads of Design</li>
                                        <li>&nbsp;Creative Directors</li>
                                        <li>&nbsp;Brand Managers</li>
                                        <li>&nbsp;Heads of Marketing</li>
                                        <li>&nbsp;Graphic Designers</li>
                                        <li>&nbsp;Directors</li>
                                        <li>&nbsp;CEO’s</li>
                                        <li>&nbsp;Business Development Directors</li>
                                        <li>&nbsp;Architects
                                        </li>
                                        <li>&nbsp;Professionals working across all forms of the design sector
                                        </li>

                                    </ul>
                                </div>
                            </p>


                        </div>
                        <div class="col-md-5">
                            <img src={image} alt="About us" class="img-fluid aboutusimg pe-xl-2" />

                        </div>

                    </div>
                </div>


            </section>

            <Footer />
        </>
    )
}

export default Visitor