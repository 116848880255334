import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/banner/hidelogo.png";
import Aniruddha from "../assets/banner/SpeakersImage/Aniruddha.jpeg";
import Amit from "../assets/banner/SpeakersImage/Ar. Amit Parashar.jpeg";
import BottomNavbar from "./BottomNavbar";
export default function SeminarCalendar() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (window.innerWidth > 500) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="d-flex w-100 justify-content-center bg-white">
        <img
          //   className="col-11"
          src={logo}
          width="87%"
          style={{ borderRadius: "7px", marginTop: "10px" }}
        />
      </div>
      <div className="container-fluid mt-4 mb-5">
        {/* <div className="row">
            <div className="col-12 text-center">
            <a className=' btn footer-btn' href={require("../pdf/Seminars.docx")} target="_blank">Download Seminar Calendar</a>
            </div>
        </div> */}

        <div className="row seminar-page">
          <div className="col-12 title text-centertitle">
            <h1 className=" mt-1 mb-4">Seminar</h1>

            <p className="text-center fs-4 fw-bold mt-3">
              27th to 29th September 2024
            </p>
            <h4>Day 1: 27/9/2024</h4>
            <div className="seminar-card">
              <div className="seminar-box flex-column">
                <span>Time:</span>
                <p>11.00 AM – 1.00 PM</p>
              </div>
              <div className="seminar-box">
                <span>Session Name:</span>
                <p>Inaugural Session</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Proceeding:</span>
                <div>
                  <p className="border-bottom pb-2">
                    Welcome Address by ( JD Student)
                  </p>
                  <p className="border-bottom pb-2">
                    Mr. Jivesh Sachdev, <br /> Ms. Sandra Agnes D'Souza &
                    Delegates
                  </p>
                  <p className="border-bottom pb-2">Lighting of a lamp</p>
                  <p className="border-bottom pb-2">Felicitation</p>
                  <p>Photo Session</p>
                </div>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Chair:</span>
                <p>Ms. Sandra Agnes D'Souza</p>
              </div>
            </div>
            <div className="seminar-card">
              <div className="seminar-box flex-column">
                <span>Time:</span>
                <p>4.00 PM – 5.00 PM</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Name:</span>
                <p>Speaker Session (Talk & QnA)</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Proceeding:</span>
                <div>
                  <p className="border-bottom pb-2">
                    Welcome & Introductory Address by Ar. Gutham Pawar
                  </p>
                  <p className="border-bottom pb-2">
                    Ar. Amit Parashar, M. Arch,. University of British Columbia,
                    Product Design from Universita Delgi Studi di Firenze,
                    Florence, Italy. Principal Architect, Design Workshop Goa.
                    <br />
                    Subject: Environment & Design
                  </p>
                  <p className="border-bottom pb-2 pt-1">Felicitation</p>
                  <p>Ar. Amit Parashar</p>
                </div>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Chair:</span>
                <div>
                  <p className="border-bottom pb-2">Ms. Sandra Agnes D'Souza</p>
                  <p>
                    Ms. Sandra Agnes D'Souza <br /> and Mr. Jivesh Sachdev
                  </p>
                </div>
              </div>
            </div>
            <h4>Day 2: 28/9/2024</h4>
            <div className="seminar-card">
              <div className="seminar-box flex-column">
                <span>Time:</span>
                <p>2:30 PM - 3: 00 PM</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Name:</span>
                <p>Speaker Session (Talk & QnA)</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Proceeding:</span>
                <div>
                  <p className="border-bottom pb-2">
                    Welcome & Introductory Address by Ar. Gutham Pawar
                  </p>
                  <p className="border-bottom pb-2">
                    Dr. Aniruddha Pawar, Ph.D., IIT Roorkee.
                    <br /> Dean, Faculty of Architecture, Planning & Design, Goa
                    University.
                    <br /> Subject: Green Buildings, Climate Responsive Design
                  </p>
                  <p className="border-bottom pb-2 pt-1">Felicitation</p>
                  <p>Ar. Amit Parashar</p>
                </div>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Chair:</span>
                <div>
                  <p className="border-bottom pb-2">Ms. Sandra Agnes D'Souza</p>
                  <p>
                    Ms. Sandra Agnes D'Souza <br /> and Mr. Jivesh Sachdev
                  </p>
                </div>
              </div>
            </div>
            <div className="seminar-card">
              <div className="seminar-box flex-column">
                <span>Time:</span>
                <p>3.30pm to 4.40pm</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Name:</span>
                <p>Speaker Session (Talk & QnA)</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Proceeding:</span>
                <div>
                  <p className="border-bottom pb-2">
                    Welcome & Introductory Address
                  </p>
                  <div className="d-flex">
                    <span>Monika Patel</span>
                    <p>Architects India</p>
                  </div>
                </div>
              </div>
              <div className="seminar-box">
                <span>Session Chair:</span>
                <p>Mr. Jivesh Sachdev</p>
              </div>
            </div>
            <div className="seminar-card">
              <div className="seminar-box flex-column">
                <span>Time:</span>
                <p>4:30 PM – 6: 00 PM</p>
              </div>
              <div className="seminar-box">
                <span>Session Name:</span>
                <p>Workshops</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Proceeding:</span>
                <div>
                  <p className="border-bottom pb-2">
                    Brief about JDSD followed by workshops
                  </p>
                  <p>(Workshops yet to be curated)</p>
                </div>
              </div>
              <div className="seminar-box">
                <p>Plan B</p>
              </div>
            </div>
            <div className="seminar-card">
              <div className="seminar-box flex-column">
                <span>Time:</span>
                <p>6:30 PM – 7: 00 PM</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Name:</span>
                <p>Prize distribution – Mr & Ms Hide Competition</p>
              </div>
              <div className="seminar-box flex-column">
                <span>Proceeding:</span>
                <div>
                  <p className="border-bottom pb-2">Hosted by JDSD Student</p>
                  <p>Winners & Participants</p>
                </div>
              </div>
              <div className="seminar-box flex-column">
                <span>Session Chair:</span>
                <div>
                  <p className="border-bottom pb-2">Ms. Sandra Agnes D'Souza</p>
                  <p>and Mr. Jivesh Sachdev</p>
                </div>
              </div>
            </div>
            <div className="seminar-card">
              <div className="seminar-box flex-column">
                <span>Time:</span>
                <p className="border-bottom pb-2">11.00 AM – 1.00 PM</p>
                <p>2:30 PM - 3: 00 PM</p>
              </div>

              <div className="seminar-box flex-column mb-2">
                <span>Proceeding:</span>
                <div>
                  <p>Speaker - Eco Furniture Recycle Bell</p>
                </div>
              </div>
            </div>
            <div className="stall_button stall_button-top-nav w-auto text-center mt-4">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSewDmVxNJ5GAk9CUxz9DHe7o90Jpqkkn2J_ACkhQIU1PnM65w/viewform?fbclid=PAZXh0bgNhZW0CMTEAAaapHnh9qmzzMNqTCdqRF06D8uWdBeE2ZForAqlzqP3WlPTqIjeVyJRfYUo_aem_FS-PEHECciDk3CUBCDZMlw"
                target="_blank"
                className="text-white">
                Book Your Slot for Seminar
              </a>
            </div>
          </div>
        </div>
        <div class="tab-content pb-5" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="step1"
            role="tabpanel"
            aria-labelledby="step1-tab">
            <div className="row m-0">
              {/* phn view card */}

              <section className="phncardseminar mx-0 px-0">
                <div class="container-fluid mt-lg-3 mt-md-3 mt-sm-4 mt-4 comonseminarpadding">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div
                        className="boxseminar1 rounded-3"
                        style={{ marginTop: "5px" }}>
                        <div className="row">
                          <div className="col-lg-6 mb-lg-2">
                            <h5>
                              <strong className="timesemianrcommon">
                                Date - 27<sup>th</sup> Sept. 2024{" "}
                              </strong>
                            </h5>
                          </div>

                          <div className="col-12">
                            <h5>
                              <strong className="timesemianrcommon">
                                Time - 4:00 PM to 5:00 PM{" "}
                              </strong>
                            </h5>
                          </div>

                          <div className="col-12">
                            <div className="row mt-3">
                              <div className="">
                                <div className="mt-2 comonfontallseminar">
                                  <div className="d-lg-flex d-block align-items-center">
                                    <div className="col-lg-2 col-3">
                                      <div className="speaker-img">
                                        <img
                                          src={Amit}
                                          alt="Speakers & Panelist"
                                          className="w-100 img-fluid"></img>
                                      </div>
                                    </div>
                                    <div className="col-lg-10 col-12">
                                      <div className="ps-lg-3">
                                        <p className=" mb-2">
                                          <b className="text-dark">
                                            {" "}
                                            Ar. Amit Parashar
                                          </b>
                                        </p>
                                        <h6 className="comonfontallseminar">
                                          <b>Qualifications :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}>
                                          M. Arch, University of British
                                          Columbia; Product Design, Università
                                          degli Studi di Firenze, Florence,
                                          Italy
                                        </div>
                                        <h6 className="comonfontallseminar">
                                          <b>Position :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}>
                                          Principal Architect, Design Workshop
                                          Goa
                                        </div>
                                        <h6 className="comonfontallseminar">
                                          <b>Subject :</b>
                                        </h6>
                                        <div
                                          className="col-12 comonfontallseminar"
                                          style={{ textAlign: "justify" }}>
                                          Environment & Design
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="boxseminar1 rounded-3">
                        <div className="row">
                          <div className="col-lg-6 mb-lg-2">
                            <h5>
                              <strong className="timesemianrcommon">
                                Date - 28<sup>th</sup> Sept. 2024{" "}
                              </strong>
                            </h5>
                          </div>

                          <div className="col-12">
                            <h5>
                              <strong className="timesemianrcommon">
                                Time - 02:30 AM - 03:00 PM{" "}
                              </strong>
                            </h5>
                          </div>

                          <div className="col-12 mt-3">
                            <div className="row">
                              <div className="col-12">
                                {/* <h6 className="">
                                    <b>Seminar Topic :</b>
                                  </h6> */}
                              </div>

                              {/* <div className="col-12 comonfontallseminar mt-2">
                                  Sustainability & its Practices in Interiors
                                  Panelist
                                </div> */}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="row mt-3">
                              <div className="col-12 mt-2">
                                <div className="row">
                                  <div className="mt-2 comonfontallseminar">
                                    <div className="d-lg-flex d-block align-items-center">
                                      <div className="col-lg-2 col-3">
                                        <div className="speaker-img">
                                          <img
                                            src={Aniruddha}
                                            alt="Speakers & Panelist"
                                            className="w-100 img-fluid"></img>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 col-12">
                                        <div className="ps-lg-3">
                                          <p className=" mb-2">
                                            {" "}
                                            <b className="text-dark">
                                              Dr. Aniruddha Pawar
                                            </b>
                                          </p>
                                          <h6 className="comonfontallseminar">
                                            <b>Qualifications :</b>
                                          </h6>
                                          <div
                                            className="col-12 comonfontallseminar"
                                            style={{ textAlign: "justify" }}>
                                            Ph.D., IIT Roorkee
                                          </div>
                                          <h6 className="comonfontallseminar">
                                            <b>Position :</b>
                                          </h6>
                                          <div
                                            className="col-12 comonfontallseminar"
                                            style={{ textAlign: "justify" }}>
                                            Dean, Faculty of Architecture,
                                            Planning & Design, Goa University
                                          </div>
                                          <h6 className="comonfontallseminar">
                                            <b>Subject :</b>
                                          </h6>
                                          <div
                                            className="col-12 comonfontallseminar"
                                            style={{ textAlign: "justify" }}>
                                            Green Buildings, Climate Responsive
                                            Design
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <BottomNavbar />
    </>
  );
}
