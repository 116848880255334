import { BrowserRouter, Route, Switch } from "react-router-dom";
import About from "./Pages/About";
import BookYourStall from "./Pages/BookYourStall";
// import Contact from "./Pages/Contact";
import ExhibitorRegistration from "./Pages/exhibitors/ExhibitorRegistration";
import Home from "./Pages/Home";

import SeminarRegistration from "./Pages/visitors/SeminarRegistration";
import VisitorRegistration from "./Pages/visitors/VisitorRegistration";

import travel from "./Pages/visitors/travel_stay";
import vistor from "./Pages/visitors/vistor";

import ExhibitorDetails from "./Comp/ExhibitorDetails";
import Overview from "./Comp/ShowOverview";
import Alfresca from "./Pages/alfresca";
import exhibitor_profile from "./Pages/exhibitors/exhibitor_profile";
import FurnishingZone from "./Pages/FurnishingZone";
import FurnitureZone from "./Pages/FurnitureZone";
import Lightingzone from "./Pages/Lightingzone";
import Surfacingzone from "./Pages/surfacingzone";
// import './App.css';


import Gallery from "./Comp/Gallery";
import Videos from "./Comp/Videos";
import CatalogQrCode from "./mobile/CatalogQrCode";
import Dashboard from "./mobile/Dashboard";
import MainScreen from "./mobile/MainScreen";
import QrScan from "./mobile/QrScan";
import SeminarCalendar from "./mobile/SeminarCalendar";
import SplashScreen from "./mobile/SplashScreen";
import TicketPage from "./mobile/TicketPage";
import VisitorForm from "./mobile/VisitorForm";
import ExhibitorRegistration_ifex from './Pages/exhibitors/ExhibitorRegistration_ifex';
import EventsComing from "./Pages/visitors/EventsComing";
import VisitorRegistrationIframe from './Pages/visitors/VisitorRegistrationIframe';
import ProtectedRoutes from "./Routes/ProtectedRoutes";
function App() {
  return (
    <BrowserRouter>
      <div className='container-fluid p-0' >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/exhibitor-details" component={ExhibitorDetails} />
          {/* <Route exact path="/contact" component={Contact} /> */}
          <Route exact path="/bookstall" component={BookYourStall} />
          <Route exact path="/visitor" component={vistor} />
          <Route exact path="/furniture-zone" component={FurnitureZone} />
          <Route exact path="/furnishing-zone" component={FurnishingZone} />
          <Route exact path="/alfresco-zone" component={Alfresca} />
          <Route exact path="/Lighting-zone" component={Lightingzone} />
          <Route exact path="/Surfacing-zone" component={Surfacingzone} />

          <Route exact path="/travel" component={travel} />
          <Route exact path="/exhibitor_profile" component={exhibitor_profile} />

          <Route exact path="/visitor-registration" component={VisitorRegistration} />
          <Route exact path="/seminar-registration" component={SeminarRegistration} />
          {/* <Route exact path="/seminar-registration" component={SeminarRegistrationComing} />
          <Route exact path="/seminar-registration" component={SeminarRegistrationComing} /> */}
          <Route exact path="/events" component={EventsComing} />

          <Route exact path="/exhibitor-registration" component={ExhibitorRegistration} />

          <Route exact path="/overview" component={Overview} />

          <Route exact path="/visitor-iframe" component={VisitorRegistrationIframe} />
          <Route exact path="/ExhibitorRegistration_ifex" component={ExhibitorRegistration_ifex} />


          {/* mobile path */}

          <Route exact path="/previewcatalogue" component={SplashScreen} />
          <Route exact path="/visitorform" component={VisitorForm} />
          <Route exact path="/qr-scan" component={QrScan} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/videos" component={Videos} />
          <ProtectedRoutes
            exact
            path="/my-ticket"
            component={TicketPage}
            isAuth={
              localStorage.getItem("CustomData") == null ? false : true
            } />
          <ProtectedRoutes
            exact
            path="/seminar-calendar"
            component={SeminarCalendar}
            isAuth={
              localStorage.getItem("CustomData") == null ? false : true
            } />
          <ProtectedRoutes
            exact
            path="/dashboard"
            component={Dashboard}
            isAuth={
              localStorage.getItem("CustomData") == null ? false : true
            } />
          <ProtectedRoutes
            exact
            path="/directorylist"
            component={MainScreen}
            isAuth={
              localStorage.getItem("CustomData") == null ? false : true
            } />

          <Route exact path="/catalogqr" component={CatalogQrCode} />






        </Switch>
      </div>
    </BrowserRouter>

  );
}

export default App;
